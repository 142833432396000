.container {
    max-width: 100%;
}
.navbar {
  padding: 0;
  align-items: stretch;
  .hi-logo {
    align-items: center;
    display: flex;
    padding-left: 10px;
    .zmdi {
        margin-right: 10px;
    }
  }
}
.listview__attrs {
  flex: 1 100%;
  margin-top: .5rem;
  display: flex;
  > span {
    background-color: #FFF;
    border: 1px solid #e9ecef;
    display: inline-block;
    font-size: .9rem;
    line-height: 100%;
    margin: .2rem .25rem .055rem 0;
    padding: .55rem .7rem;
  }
}
.card {
    box-shadow: 0 1px 2px rgba(0,0,0,.075);
    width: 100%;
    .card-header {
        background-color: transparent;
        border-bottom: none;
    }
    .card-body {
        padding: 0;
    }
}

.campaigns-reports {
    .asset.card {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .asset.card-body {
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.125);
        box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
        margin-bottom: 50px;
    }
}

.campaigns-keywords {
    .asset-table {
        .asset-text {
            p {
                margin-bottom: 0;
                small {
                    color: #b9bec5 !important;
                }
            }
        }
    }
}

.graphs {
    display: flex;
}

.btn.active {
  &.favorable {
    background-color: $green;
  }
  &.neutral {
    background-color: var(--gray);
  }
  &.unfavorable {
    background-color: $red;
  }
}

.spinner {
    margin-top: 10px;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.spinner > div {
  background-color: #1592ff;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
tr.inactive {
    opacity: 0.2;
}
td.status {
  &.active,
  button.btn {
    color: #4caf50;
  }
  &.crawling,
  button.btn.crawling {
    color: #03a9f4;
    .zmdi-refresh {
      animation: loading 1s linear infinite;
    }
  }
  &.error,
  button.btn.error {
    color: #03a9f4;
    .zmdi-refresh::before {
      content: '\f1b6';
    }
  }
}

@keyframes loading {
  to {transform: rotate(360deg);}
}

.dataTables_wrapper .dataTables_filter {
    width: 100%;
}

.dtr-details {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin-right: 10px;
        color: #999;
        .dtr-title {
            font-weight: bold;
            &:after {
                content: ": ";
            }
        }
        span {
            .zmdi {
                display: none;
            }
            // width: 50%;
        }
    }
}
