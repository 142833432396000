.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
}
.fileinput {
  margin-bottom: 9px;
  display: inline-block;
}
.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
}
.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
}
.fileinput .thumbnail > img {
  max-height: 100%;
}
.fileinput .btn {
  vertical-align: middle;
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
  display: table;
}
.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}
.fileinput.input-group > .btn-file {
  z-index: 1;
}
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 2px 2px 0;
}
.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 2px 2px 0;
}
.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 2px 2px 0;
}
.form-group.has-warning .fileinput .fileinput-preview {
  color: #ffa829;
}
.form-group.has-warning .fileinput .thumbnail {
  border-color: #ff760f;
}
.form-group.has-error .fileinput .fileinput-preview {
  color: #f46963;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: #f24b60;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: #67bd6a;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: #61b555;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
/*-------------------------------
    Material Background Colors
--------------------------------*/
.bgm-white {
  background-color: #ffffff !important;
}
.c-white {
  color: #ffffff !important;
}
.bgm-black {
  background-color: #000000 !important;
}
.c-black {
  color: #000000 !important;
}
.bgm-brown {
  background-color: #795548 !important;
}
.c-brown {
  color: #795548 !important;
}
.bgm-pink {
  background-color: #E91E63 !important;
}
.c-pink {
  color: #E91E63 !important;
}
.bgm-red {
  background-color: #f1453d !important;
}
.c-red {
  color: #f1453d !important;
}
.bgm-blue {
  background-color: #2196F3 !important;
}
.c-blue {
  color: #2196F3 !important;
}
.bgm-purple {
  background-color: #BA68C8 !important;
}
.c-purple {
  color: #BA68C8 !important;
}
.bgm-deeppurple {
  background-color: #673AB7 !important;
}
.c-deeppurple {
  color: #673AB7 !important;
}
.bgm-lightblue {
  background-color: #03A9F4 !important;
}
.c-lightblue {
  color: #03A9F4 !important;
}
.bgm-cyan {
  background-color: #00BCD4 !important;
}
.c-cyan {
  color: #00BCD4 !important;
}
.bgm-teal {
  background-color: #009688 !important;
}
.c-teal {
  color: #009688 !important;
}
.bgm-green {
  background-color: #4CAF50 !important;
}
.c-green {
  color: #4CAF50 !important;
}
.bgm-lightgreen {
  background-color: #8BC34A !important;
}
.c-lightgreen {
  color: #8BC34A !important;
}
.bgm-lime {
  background-color: #CDDC39 !important;
}
.c-lime {
  color: #CDDC39 !important;
}
.bgm-yellow {
  background-color: #607d8b !important;
}
.c-yellow {
  color: #607d8b !important;
}
.bgm-amber {
  background-color: #FFC107 !important;
}
.c-amber {
  color: #FFC107 !important;
}
.bgm-orange {
  background-color: #FF9800 !important;
}
.c-orange {
  color: #FF9800 !important;
}
.bgm-deeporange {
  background-color: #FF5722 !important;
}
.c-deeporange {
  color: #FF5722 !important;
}
.bgm-gray {
  background-color: #9E9E9E !important;
}
.c-gray {
  color: #9E9E9E !important;
}
.bgm-bluegray {
  background-color: #607D8B !important;
}
.c-bluegray {
  color: #607D8B !important;
}
.bgm-indigo {
  background-color: #3F51B5 !important;
}
.c-indigo {
  color: #3F51B5 !important;
}
/*-------------------------
    Background Colors
--------------------------*/
.bg-black-trp {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
/*-------------------------
    Border
--------------------------*/
.b-0 {
  border: 0 !important;
}
/*-------------------------
    width
--------------------------*/
.w-100 {
  width: 100% !important;
}
/*-------------------------
    Border Radius
--------------------------*/
.brd-2 {
  border-radius: 2px;
}
/*------------------------------------------------------------------------------------------------------------
    Media - Overriding the Media object to 3.2 version in order to prevent issues like text overflow.
--------------------------------------------------------------------------------------------------------------*/
.media {
  overflow: visible;
}
.media:before,
.media:after {
  content: " ";
  display: table;
}
.media:after {
  clear: both;
}
.media:before,
.media:after {
  content: " ";
  display: table;
}
.media:after {
  clear: both;
}
.media > .pull-left {
  padding-right: 15px;
}
.media > .pull-right {
  padding-left: 15px;
}
.media-heading {
  font-size: 14px;
  margin-bottom: 10px;
}
.media-body {
  zoom: 1;
  display: block;
  width: auto;
}
.media-object {
  border-radius: 2px;
}
.close {
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-weight: normal;
  text-shadow: none;
}
.close:hover {
  color: inherit;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dl-horizontal dt {
  text-align: left;
}
*,
button,
input,
i,
a {
  -webkit-font-smoothing: antialiased;
}
*,
*:active,
*:hover,
*:focus {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
html {
  overflow-x: hidden;
  -ms-overflow-style: none;
}
html:not(.ie9) body {
  overflow: visible;
}
html.ismobile * {
  cursor: pointer;
}
html,
body {
  min-height: 100vh;
}
body {
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
}
audio,
video {
  outline: none;
}
p {
  margin-bottom: 20px;
}
.page-break { display:block; clear:both; page-break-after:always; }
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small {
  font-size: 12px;
}
#main {
  position: relative;
  padding-top: 110px;
  min-height: 100vh;
}
body.pdf #main {
  padding-top: 0;
}
.sidebar-toggled #main {
  height: 100vh;
  overflow: hidden;
}
@media (min-width: 767px) {
  .container {
    padding-left: 75px;
    padding-right: 75px;
  }
}
.container.container-alt {
  max-width: 1170px;
}
@media (min-width: 768px) and (max-width: 1279px) {
}
#content.content-alt {
  max-width: 1200px;
  margin: 0 auto;
}
.clist {
  list-style: none;
}
.clist > li:before {
  font-family: 'Material-Design-Iconic-Font';
  margin: 0 10px 0 -20px;
  vertical-align: middle;
}
.clist.clist-angle > li:before {
  content: "\f2fb";
}
.clist.clist-check > li:before {
  content: "\f26b";
}
.clist.clist-star > li:before {
  content: "\f27d";
}
/*--------------------------------------------------
    Common header classes & IDs
    Do not remove this
---------------------------------------------------*/
.h-inner {
  list-style: none;
  padding: 17px 0;
  margin-bottom: 0;
  position: relative;
}
.h-inner > li:not(.pull-right) {
  float: left;
}
.hi-client-logos {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 50px;
  text-align: center;
  img {
    height: 100%;
    margin-right: 10px;
  }
}
.hi-logo {
  padding-top: 5px;
}
.hi-logo a {
  color: #000;
  display: block;
  font-size: 16px;
}
.hi-trigger {
  position: relative;
  margin-left: -5px;
  cursor: pointer;
  display: none !important;
}
.hi-trigger:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.22);
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  z-index: 0;
  margin-top: -22px;
  margin-left: -22px;
}
.hi-trigger.toggled:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.hi-menu {
  list-style: none;
  padding: 0;
}
.hi-menu > li {
  display: inline-block;
  margin: 0 1px;
  vertical-align: top;
  min-width: 50px;
}
@media (max-width: 767px) {
  .hi-menu > li {
    position: static !important;
  }
}
.hi-menu > li .dropdown-menu {
  top: -5px;
}
.hi-menu > li .dropdown-menu-lg {
  padding: 0;
}
.hi-menu > li .dropdown-menu-lg .lg-body {
  min-height: 350px;
  overflow-x: hidden;
}
.hi-menu > li > a {
  color: #6c6f74;
  display: block;
  text-align: center;
  z-index: 1;
  position: relative;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  border-radius: 2px;
}
.hi-menu > li > a > .him-icon {
  font-size: 24px;
  line-height: 36px;
}
.hi-menu > li > a > .him-label {
  line-height: 35px;
  white-space: nowrap;
  padding: 0 10px;
  font-size: 14px;
  text-transform: uppercase;
}
.hi-menu > li > a > .him-counts {
  color: #fff;
  position: absolute;
  font-style: normal;
  background: #f1453d;
  padding: 1px 5px;
  border-radius: 2px;
  right: 7px;
  top: -3px;
  font-size: 10px;
  line-height: 15px;
}
.hi-menu > li.toggled > a,
.hi-menu > li:hover > a {
  background-color: rgba(0, 0, 0, 0.08);
}
@media (max-width: 767px) {
  .hi-menu .dropdown-menu-lg {
    width: calc(100% - 28px) !important;
  }
  .hi-menu .dropdown-menu {
    right: 14px;
    top: 55px !important;
  }
}
.him-notification:before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 70px);
  background: url(/assets/notifications.png) no-repeat center;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(0) rotate(-180deg);
  -ms-transform: scale(0) rotate(-180deg);
  -o-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
  opacity: 0;
  filter: alpha(opacity=0);
  top: 42px;
}
.him-notification.empty:before {
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  -o-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1;
  filter: alpha(opacity=100);
}
/* Full Screen */
:-webkit-full-screen [data-ma-action="fullscreen"] {
  display: none;
}
:-moz-full-screen [data-ma-action="fullscreen"] {
  display: none;
}
:-ms-fullscreen [data-ma-action="fullscreen"] {
  display: none;
}
:full-screen [data-ma-action="fullscreen"] {
  display: none;
}
:fullscreen [data-ma-action="fullscreen"] {
  display: none;
}
/* ----------------------------- End common header classes and IDs------------------------------------- */
/*--------------------------------------------------
    For header type 1 only
    You may remove these if you opt header 2
---------------------------------------------------*/
#header {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  min-height: 70px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: fixed;
  z-index: 11;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 20px;
}
@media (max-width: 767px) {
  #header {
    padding: 0px 8px;
  }
}
#header .hi-logo a {
  padding: 7px 10px;
}
#header .ma-backdrop {
  position: absolute;
}
@media (max-width: 1279px) {
  #header .hi-trigger {
    display: inline-block !important;
  }
}
#header.search-toggled .h-search-wrap {
  top: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}
.h-search-wrap {
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 10;
}
.h-search-wrap input[type="text"] {
  border: 0;
  height: 40px;
  padding: 0 10px 0 55px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #efefef;
  width: 100%;
}
.h-search-wrap .hsw-close {
  position: absolute;
  top: 15px;
  font-size: 23px;
  font-style: normal;
  width: 45px;
  text-align: center;
  border-radius: 2px 0px 0px 2px;
  cursor: pointer;
  left: 15px;
  height: 40px;
  padding-top: 9px;
}
.h-search-wrap .hsw-close:hover {
  background-color: #e3e3e3;
}
@media (max-width: 767px) {
  .h-search-wrap .hsw-close {
    right: 7px;
  }
}
.hsw-inner {
  position: relative;
  padding: 15px;
  max-width: 700px;
  display: block;
  margin: 0 auto;
}
/* ----------------------------- End header type 1 ------------------------------------- */
/*--------------------------------------------------
    For Header type 2 only
    You may remove these if you opt header 1
---------------------------------------------------*/
#header-alt {
  position: relative;
  margin-bottom: -50px;
  z-index: 10;
  background-color: #fff;
}
#header-alt:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 49px;
  z-index: -1;
  border-bottom: 1px solid #dcddde;
  border-top: 1px solid #dcddde;
}
#header-alt .hi-trigger {
  top: -7px;
  left: -15px;
}
@media (max-width: 991px) {
  #header-alt .hi-trigger {
    display: inline-block !important;
  }
}
#header-alt .ma-backdrop {
  position: absolute;
}
#header-alt .ha-search {
  margin-bottom: 25px;
  padding-right: 25px;
}
#header-alt .ha-search input[type="text"] {
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  padding: 6px 0 6px 30px;
}
#header-alt .ha-search input[type="text"]::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
#header-alt .ha-search input[type="text"]:-ms-input-placeholder {
  color: #fff;
}
#header-alt .ha-search input[type="text"]::-webkit-input-placeholder {
  color: #fff;
}
#header-alt .ha-search input[type="text"].ie9-placeholder {
  color: #fff !important;
}
#header-alt .ha-search .fg-line {
  max-width: 500px;
  position: relative;
}
#header-alt .ha-search .fg-line:after {
  background: #607d8b;
}
#header-alt .ha-search .fg-line:before {
  content: '\f1c3';
  font-family: 'Material-Design-Iconic-Font';
  position: absolute;
  left: 0;
  bottom: 1px;
  color: #fff;
  font-size: 22px;
}
.ha-menu > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ha-menu > ul > li {
  display: inline-block;
  vertical-align: top;
}
.ha-menu > ul > li:not(.active) > *:not(ul) {
  color: #6d7074;
}
.ha-menu > ul > li.active > a {
  color: #607d8b;
  box-shadow: inset 0px -3px 0 0px #607d8b;
}
.ha-menu > ul > li > a {
  text-transform: uppercase;
  padding: 15px 12px;
  display: block;
}
.ha-menu > ul > li .dropdown-menu {
  min-width: 100%;
}
.s-profile > a {
  display: block;
  height: 129px;
  margin-bottom: 5px;
  width: 100%;
  background: url(/assets/profile-menu.png) no-repeat left top;
  background-size: 100%;
}
.s-profile > a .sp-pic {
  padding: 12px;
}
.s-profile > a .sp-pic > img {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.14);
  box-sizing: content-box;
}
.s-profile > a .sp-info {
  background: rgba(0, 0, 0, 0.37);
  padding: 7px 14px;
  color: #fff;
  margin-top: 20px;
  position: relative;
}
.s-profile > a .sp-info > i {
  font-size: 19px;
  line-height: 100%;
  position: absolute;
  right: 15px;
  top: 7px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.s-profile .main-menu {
  display: none;
  margin: 0 0 0;
}
.s-profile.toggled .sp-info > i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.main-menu {
  list-style: none;
  padding-left: 0;
  margin: 20px 0 0 0;
}
.main-menu a {
  -webkit-transition: color;
  -o-transition: color;
  transition: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.main-menu a:hover,
.main-menu .active > a,
.main-menu a.active {
  color: #262626;
}
.main-menu > li > a {
  padding: 14px 20px 14px 65px;
  display: block;
  font-weight: 500;
  position: relative;
  color: #4C4C4C;
}
.main-menu > li > a > i {
  position: absolute;
  left: 25px;
  font-size: 18px;
  top: 2px;
  width: 25px;
  text-align: center;
  padding: 13px 0;
}
.main-menu > li > a:hover,
.main-menu > li.active > a {
  background-color: #f7f7f7;
}
.sub-menu > a {
  position: relative;
}
.sub-menu > a:before,
.sub-menu > a:after {
  position: absolute;
  top: 50%;
  margin-top: -11px;
  font-family: 'Material-Design-Iconic-Font';
  font-size: 17px;
  right: 15px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.sub-menu > a:before {
  content: "\f278";
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.sub-menu > a:after {
  content: "\f273";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.sub-menu.toggled > a:before {
  content: "\f278";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.sub-menu.toggled > a:after {
  content: "\f273";
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.sub-menu ul {
  list-style: none;
  display: none;
  padding: 0;
}
.sub-menu ul > li > a {
  padding: 8px 20px 8px 65px;
  font-weight: 500;
  display: block;
  color: #989898;
}
.sub-menu ul > li:first-child > a {
  padding-top: 14px;
}
.sub-menu ul > li:last-child > a {
  padding-bottom: 16px;
}
.sub-menu ul > li ul {
  font-size: 12px;
  margin: 10px 0;
  background-color: #F7F7F7;
}
.sub-menu.active > ul {
  display: block;
}
/*-------------------------
    For Stupid IE9
--------------------------*/
@media (max-width: 1279px) {
  .ie9 #sidebar {
    display: none;
  }
  .ie9 #sidebar.toggled {
    display: block;
  }
}
.ie9 .sidebar-alt {
  display: none;
}
.ie9 .sidebar-alt.toggled {
  display: block;
}
.dropdown:not([data-animation]) .dropdown-menu {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.dropdown-menu {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  border-radius: 3px;
  top: -1px;
  margin: 0;
  border: 0;
}
.dropdown-menu > li > a {
  padding: 10px 20px;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.dropdown-menu[class*="bgm-"] > li > a {
  color: #fff;
}
.dropup .dropdown-menu {
  bottom: -2px;
}
.dm-icon > li > a > .zmdi {
  line-height: 100%;
  vertical-align: top;
  font-size: 18px;
  width: 28px;
}
.dropdown-menu-lg {
  width: 300px;
}
.dropdown-header {
  padding: 3px 17px;
  margin-top: 10px;
  color: #b1b1b1;
  text-transform: uppercase;
  font-weight: normal;
}
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.list-group {
  margin-bottom: 0;
}
.list-group .list-group-item {
  border: 0;
  margin: 0;
  padding: 15px 30px;
}
.list-group .list-group-item > .checkbox.pull-left {
  margin: 0;
}
.list-group.lg-odd-black .list-group-item:nth-child(odd) {
  background-color: #f9f9f9;
}
.list-group.lg-even-black .list-group-item:nth-child(even) {
  background-color: #f9f9f9;
}
.lg-header {
  text-align: center;
  padding: 15px 10px 13px;
  line-height: 100%;
  text-transform: uppercase;
  border-bottom: 1px solid #F0F0F0;
  font-weight: 500;
  color: #4C4C4C;
  margin-bottom: 10px;
}
.lg-header .actions {
  position: absolute;
  top: 5px;
  right: 10px;
}
.lgi-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.lgi-heading {
  color: #000;
  margin-bottom: 4px;
  display: block;
}
.lgi-heading,
.lgi-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lgi-text {
  display: block;
  font-size: 12px;
  color: #777777;
}
.lgi-text:not(:last-child) {
  margin-bottom: 4px;
}
.lgi-checkbox {
  margin-top: 8px;
  margin-bottom: 0;
}
.lgi-attrs {
  list-style: none;
  padding: 0;
  margin: 0;
}
.lgi-attrs > li {
  display: inline-block;
  border: 1px solid #f0f0f0;
  margin: 2px 2px 2px 0;
  padding: 2px 5px;
  font-size: 12px;
  color: #777777;
}
.lgi-attrs > li > a {
  display: block;
}
.progress {
  box-shadow: none;
  border-radius: 0;
  height: 5px;
  margin-bottom: 0;
}
.progress .progress-bar {
  box-shadow: none;
}
#chat {
  padding: 20px 0 5px;
  width: 280px;
  right: -300px;
  box-shadow: 0 0 20px rgba(14, 18, 21, 0.38);
}
#chat.toggled {
  right: 0;
}
#chat .chat-search {
  padding: 20px 20px 15px 20px;
}
#chat .chat-search .form-control {
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
  background-color: transparent;
}
#chat .chat-search .form-control:focus {
  padding: 0 30px 0 0;
}
#chat .chat-search .form-control:focus + .zmdi-search {
  left: calc(100% - 15px);
}
#chat .chat-search .form-control,
#chat .chat-search .zmdi-search {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
#chat .chat-search .zmdi-search {
  position: absolute;
  left: 0;
  bottom: 6px;
  font-size: 20px;
}
#chat .lg-body {
  height: calc(100% - 70px);
}
/*------------------------------
    Chat Status Icons
-------------------------------*/
[class*="chat-status"] {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: -3px;
  right: 12px;
  border: 2px solid #FFF;
}
/* Simple Mixin */
.chat-status-online {
  box-shadow: 0 0 0 1px #1EC01E;
  background: #1EC01E;
}
.chat-status-offline {
  box-shadow: 0 0 0 1px #E73F3F;
  background: #E73F3F;
}
.chat-status-busy {
  box-shadow: 0 0 0 1px #FFA500;
  background: #FFA500;
}
.tab-nav {
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin: 0;
  // overflow: auto;
  overflow: none;
  // box-shadow: inset 0 -2px 0 0 #dcddde;
  width: 100%;
}
.tab-nav li {
    display: inline-block;
    vertical-align: top;
    &:first-child > a {
        border-left: none;
    }
    &:last-child > a {
        border-right: none;
    }
}
.tab-nav li > a {
    border-radius: 0 !important;
    display: inline-block;
    color: #7a7a7a;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
    transition: all;
    transition-duration: 250ms;
    width: 100%;
}
// .tab-nav li > a:after {
//   content: "";
//   height: 2px;
//   position: absolute;
//   width: 100%;
//   left: 0;
//   bottom: 0;
//   transition: all;
//   transition-duration: 250ms;
//   transform: scale(0);
// }
@media (min-width: 768px) {
  .tab-nav li > a {
    padding: 15px;
  }
}
@media (max-width: 768px) {
  .tab-nav li > a {
    padding: 15px 8px;
  }
}
.tab-nav li.active > a {
  color: #000;
}
.tab-nav li.active > a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.tab-nav.tab-nav-right {
  text-align: right;
}
.tab-nav.tn-justified > li {
  display: table-cell;
  width: 1%;
  text-align: center;
}
.tab-nav.tn-icon > li .zmdi {
  font-size: 22px;
  line-height: 100%;
  min-height: 25px;
}
.tab-nav:not([data-tab-color]) > li > a:after {
  background: #2196F3;
}
.tab-nav[data-tab-color="green"] > li > a:after {
  background: #4CAF50;
}
.tab-nav[data-tab-color="red"] > li > a:after {
  background: #f1453d;
}
.tab-nav[data-tab-color="teal"] > li > a:after {
  background: #009688;
}
.tab-nav[data-tab-color="amber"] > li > a:after {
  background: #FFC107;
}
.tab-nav[data-tab-color="black"] > li > a:after {
  background: #000000;
}
.tab-nav[data-tab-color="cyan"] > li > a:after {
  background: #00BCD4;
}
.tab-nav[data-tab-color="dark-teal"] > li > a:after {
  background: #607d8b;
}
.tab-content {
  padding: 20px 0;
}
.card {
  position: relative;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  border-radius: 2px;
}
.card .card-header {
  position: relative;
  display: block;
  padding: 26px 30px;
  border-radius: 2px 2px 0 0;
}
.card .card-header h2 {
  margin: 0;
  line-height: 100%;
  font-size: 16px;
  font-weight: 500;
  .zmdi-help-outline {
    cursor: pointer;
  }
}
.card .card-header h2 small {
  display: block;
  margin-top: 8px;
  color: #AEAEAE;
  line-height: 160%;
}
.card .card-header.ch-alt:not([class*="bgm-"]) {
  background-color: #f7f7f7;
}
.card .card-header .actions {
  position: absolute;
  right: 15px;
  z-index: 2;
  top: 15px;
}
.card .card-header .btn-float {
  right: 25px;
  bottom: -23px;
  z-index: 1;
}
.card .card-header[class*="bgm-"] h2 {
  color: #fff;
}
.card .card-header[class*="bgm-"] h2 small {
  color: rgba(255, 255, 255, 0.7);
}
.card .card-body.card-padding {
  padding: 26px 30px;
}
.card .card-body.card-padding-sm {
  padding: 15px;
}
.card-header:not(.ch-alt):not([class*="bgm-"]) + .card-padding {
  padding-top: 0;
}
.card-light .card-header h2,
.card-light .card-header h2 small {
  color: #fff;
}
.chart-edge {
  margin: 20px -8px 0 -10px;
  overflow: hidden;
}
.chart-edge .flot-chart {
  bottom: -14px;
}
.charts-row {
  margin-top: 50px;
  margin-bottom: 20px;
}
.mini-charts-item {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 30px;
}
.mini-charts-item .chart {
  padding: 21px 18px 0;
  float: left;
}
.mini-charts-item .chart.chart-pie {
  margin: 0 20px;
  padding: 14px 11px;
}
.mini-charts-item .count {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.9);
  padding: 16px 12px;
}
.mini-charts-item .count > h2 {
  margin: 0;
  line-height: 100%;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
}
.mini-charts-item .count > small {
  margin-bottom: 2px;
  display: block;
}
.mini-charts-item .count > h2,
.mini-charts-item .count > small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mini-charts-item > .clearfix,
.mini-charts-item > .dl-horizontal dd,
.mini-charts-item > .container,
.mini-charts-item > .container-fluid,
.mini-charts-item > .row,
.mini-charts-item > .form-horizontal .form-group,
.mini-charts-item > .btn-toolbar,
.mini-charts-item > .btn-group-vertical > .btn-group,
.mini-charts-item > .nav,
.mini-charts-item > .navbar,
.mini-charts-item > .navbar-header,
.mini-charts-item > .navbar-collapse,
.mini-charts-item > .pager,
.mini-charts-item > .panel-body,
.mini-charts-item > .modal-header,
.mini-charts-item > .modal-footer {
  position: relative;
  z-index: 1;
}
.mini-charts-item:before {
  -webkit-transition: width;
  -o-transition: width;
  transition: width;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  content: "";
  width: 105px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
}
.mini-charts-item:hover .count {
  color: #fff !important;
}
.mini-charts-item:hover:before {
  width: 100%;
}
/*------------------------------
    Sparkline Tooltip
-------------------------------*/
#jqstooltip {
  min-width: 21px;
  min-height: 23px;
  text-align: center;
  border: 0;
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
#jqstooltip .jqsfield {
  font-size: 12px;
  font-weight: 700;
  font-family: inherit;
  text-align: center;
  color: #333;
}
#jqstooltip .jqsfield > span {
  display: none;
}
/*------------------------------
    Easy Pie Charts
-------------------------------*/
.epc-item {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  position: relative;
  margin-bottom: 30px;
  padding: 30px 20px;
  text-align: center;
}
.easy-pie {
  display: inline-block;
  position: relative;
  padding: 0 0 10px;
}
.easy-pie .percent {
  position: absolute;
  font-weight: 300;
  width: 100%;
  line-height: 100%;
  left: 4px;
  color: #fff;
}
.easy-pie .percent:after {
  content: "%";
}
.easy-pie.main-pie .percent {
  margin-top: 49px;
  font-size: 50px;
  text-align: center;
}
.easy-pie.main-pie .percent:after {
  font-size: 30px;
}
.easy-pie.main-pie .pie-title {
  color: #fff;
}
.easy-pie:not(.main-pie) .percent {
  font-size: 24px;
  margin-top: 33px;
}
.easy-pie:not(.main-pie) .percent:after {
  font-size: 20px;
}
.easy-pie .pie-title {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -3px;
  left: 0;
}
/*------------------------------
    Recent Items Table Chart
-------------------------------*/
#recent-items-chart {
  width: calc(100% + 19px);
  height: 150px;
  margin: -20px -10px 0;
  bottom: -10px;
}
/*------------------------------
    Flot Chart
-------------------------------*/
[class*="flot-chart"] {
  width: 100%;
  display: block;
}
.flot-chart {
  height: 250px;
}
.flot-chart-pie {
  height: 300px;
}
@media (min-width: 768px) {
  .flot-chart-pie {
    margin-bottom: 20px;
  }
}
.flot-tooltip,
#flotTip {
  position: absolute;
  color: #333;
  display: none;
  font-size: 12px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 3px 10px;
  background-color: #fff;
  z-index: 99999;
}
[class*="flc-"] {
  text-align: center;
  margin: 20px 0 -5px;
}
[class*="flc-"] table {
  display: inline-block;
}
[class*="flc-"] .legendColorBox > div {
  border: #fff !important;
}
[class*="flc-"] .legendColorBox > div > div {
  border-radius: 50%;
}
[class*="flc-"] .legendLabel {
  padding: 0 8px 0 3px;
}
/*------------------------------
    Maps
-------------------------------*/
.map-sm {
  width: 100%;
  height: 220px;
}
.map-rg {
  width: 100%;
  height: 350px;
}
.dw-item {
  position: relative;
  min-height: 400px;
  margin-bottom: 30px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.dwi-header {
  position: relative;
}
.dwi-header-img {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 155px;
}
.dw-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.dwih-title {
  padding: 12px 20px;
  position: absolute;
  width: 100%;
  left: 0;
}
/*-------------------------
   Site Visits
--------------------------*/
#site-visits .dwi-header {
  padding-bottom: 38px;
  margin-bottom: 12px;
  background-color: rgba(255, 255, 255, 0.05);
}
#site-visits .dwi-header canvas {
  width: 100% !important;
}
#site-visits .dwih-title {
  bottom: 0;
  color: #fff;
}
#site-visits .sv-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}
#site-visits .sv-item small {
  color: rgba(255, 255, 255, 0.7);
}
#site-visits .sv-item h3 {
  font-weight: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}
#site-visits .sv-item .pull-right {
  margin-top: 5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/*-------------------------
    Best Selling Item
--------------------------*/
#best-selling {
  background-color: #fff;
}
#best-selling .dwi-header .dwih-title {
  padding-bottom: 30px;
  top: 0;
  color: #fff;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99000000', endColorstr='#00000000', GradientType=0);
}
#best-selling .dwi-header .bs-main {
  padding: 15px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#99000000', GradientType=0);
}
#best-selling .dwi-header .bs-main > h2 {
  font-weight: 400;
  font-size: 20px;
  margin: 5px 0 0 0;
  line-height: 100%;
  color: #fff;
}
/*-------------------------
    Weather
--------------------------*/
#weather-widget {
  color: #fff;
  padding: 20px 20px 0;
}
#weather-widget .weather-status {
  font-size: 40px;
  line-height: 100%;
}
#weather-widget .weather-icon {
  text-align: center;
  margin-top: 10px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  /* Weather Icons */
}
#weather-widget .weather-icon.wi-0 {
  background-image: url("/assets/icons/weather/0.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-0 {
    background-image: url("/assets/icons/weather/0@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-1 {
  background-image: url("/assets/icons/weather/1.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-1 {
    background-image: url("/assets/icons/weather/1@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-2 {
  background-image: url("/assets/icons/weather/2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-2 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-3 {
  background-image: url("/assets/icons/weather/3.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-3 {
    background-image: url("/assets/icons/weather/3@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-4 {
  background-image: url("/assets/icons/weather/2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-4 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-5 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-5 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-6 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-6 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-7 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-7 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-8 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-8 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-9 {
  background-image: url("/assets/icons/weather/9.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-9 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-10 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-10 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-11 {
  background-image: url("/assets/icons/weather/9.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-11 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-12 {
  background-image: url("/assets/icons/weather/9.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-12 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-13 {
  background-image: url("/assets/icons/weather/9.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-13 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-14 {
  background-image: url("/assets/icons/weather/9.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-14 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-15 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-15 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-16 {
  background-image: url("/assets/icons/weather/9.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-16 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-17 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-17 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-18 {
  background-image: url("/assets/icons/weather/18.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-18 {
    background-image: url("/assets/icons/weather/18@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-19 {
  background-image: url("/assets/icons/weather/19.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-19 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-20 {
  background-image: url("/assets/icons/weather/19.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-20 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-21 {
  background-image: url("/assets/icons/weather/19.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-21 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-22 {
  background-image: url("/assets/icons/weather/19.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-22 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-23 {
  background-image: url("/assets/icons/weather/19.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-23 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-24 {
  background-image: url("/assets/icons/weather/24.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-24 {
    background-image: url("/assets/icons/weather/24@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-25 {
  background-image: url("/assets/icons/weather/24.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-25 {
    background-image: url("/assets/icons/weather/24@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-26 {
  background-image: url("/assets/icons/weather/26.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-26 {
    background-image: url("/assets/icons/weather/26@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-27 {
  background-image: url("/assets/icons/weather/27.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-27 {
    background-image: url("/assets/icons/weather/27@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-28 {
  background-image: url("/assets/icons/weather/28.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-28 {
    background-image: url("/assets/icons/weather/28@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-29 {
  background-image: url("/assets/icons/weather/27.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-29 {
    background-image: url("/assets/icons/weather/27@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-30 {
  background-image: url("/assets/icons/weather/28.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-30 {
    background-image: url("/assets/icons/weather/28@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-31 {
  background-image: url("/assets/icons/weather/31.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-31 {
    background-image: url("/assets/icons/weather/31@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-32 {
  background-image: url("/assets/icons/weather/32.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-32 {
    background-image: url("/assets/icons/weather/32@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-33 {
  background-image: url("/assets/icons/weather/31.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-33 {
    background-image: url("/assets/icons/weather/31@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-34 {
  background-image: url("/assets/icons/weather/32.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-34 {
    background-image: url("/assets/icons/weather/32@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-35 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-35 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-36 {
  background-image: url("/assets/icons/weather/32.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-36 {
    background-image: url("/assets/icons/weather/32@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-37 {
  background-image: url("/assets/icons/weather/2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-37 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-38 {
  background-image: url("/assets/icons/weather/2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-38 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-39 {
  background-image: url("/assets/icons/weather/2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-39 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-40 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-40 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-41 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-41 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-42 {
  background-image: url("/assets/icons/weather/9.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-42 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-43 {
  background-image: url("/assets/icons/weather/5.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-43 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-44 {
  background-image: url("/assets/icons/weather/27.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-44 {
    background-image: url("/assets/icons/weather/27@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-45 {
  background-image: url("/assets/icons/weather/2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-45 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-46 {
  background-image: url("/assets/icons/weather/18.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-46 {
    background-image: url("/assets/icons/weather/18@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-47 {
  background-image: url("/assets/icons/weather/2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-47 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-info {
  list-style: none;
  padding: 0;
  margin: 3px 0 0 0;
}
#weather-widget .weather-info > li {
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.39);
  padding: 2px 10px 3px;
  margin-right: 5px;
}
#weather-widget .dw-footer {
  background: rgba(0, 0, 0, 0.04);
  padding: 10px 20px;
}
#weather-widget .weather-list {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#weather-widget .weather-list > span {
  margin-right: 7px;
  display: inline-block;
  line-height: 40px;
  vertical-align: top;
}
#weather-widget .weather-list > span.weather-list-icon {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}
#weather-widget .weather-list > span.weather-list-icon.wi-0 {
  background-image: asset-url('icons/weather/0.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-1 {
  background-image: asset-url('icons/weather/1.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-2 {
  background-image: asset-url('icons/weather/2.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-3 {
  background-image: asset-url('icons/weather/3.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-4 {
  background-image: asset-url('icons/weather/2.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-5 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-6 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-7 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-8 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-9 {
  background-image: asset-url('icons/weather/9.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-10 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-11 {
  background-image: asset-url('icons/weather/9.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-12 {
  background-image: asset-url('icons/weather/9.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-13 {
  background-image: asset-url('icons/weather/9.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-14 {
  background-image: asset-url('icons/weather/9.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-15 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-16 {
  background-image: asset-url('icons/weather/9.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-17 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-18 {
  background-image: asset-url('icons/weather/18.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-19 {
  background-image: asset-url('icons/weather/19.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-20 {
  background-image: asset-url('icons/weather/19.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-21 {
  background-image: asset-url('icons/weather/19.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-22 {
  background-image: asset-url('icons/weather/19.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-23 {
  background-image: asset-url('icons/weather/19.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-24 {
  background-image: asset-url('icons/weather/24.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-25 {
  background-image: asset-url('icons/weather/24.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-26 {
  background-image: asset-url('icons/weather/26.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-27 {
  background-image: asset-url('icons/weather/27.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-28 {
  background-image: asset-url('icons/weather/28.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-29 {
  background-image: asset-url('icons/weather/27.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-30 {
  background-image: asset-url('icons/weather/28.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-31 {
  background-image: asset-url('icons/weather/31.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-32 {
  background-image: asset-url('icons/weather/32.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-33 {
  background-image: asset-url('icons/weather/31.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-34 {
  background-image: asset-url('icons/weather/32.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-35 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-36 {
  background-image: asset-url('icons/weather/32.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-37 {
  background-image: asset-url('icons/weather/2.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-38 {
  background-image: asset-url('icons/weather/2.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-39 {
  background-image: asset-url('icons/weather/2.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-40 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-41 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-42 {
  background-image: asset-url('icons/weather/9.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-43 {
  background-image: asset-url('icons/weather/5.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-44 {
  background-image: asset-url('icons/weather/27.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-45 {
  background-image: asset-url('icons/weather/2.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-46 {
  background-image: asset-url('icons/weather/18.png');
}
#weather-widget .weather-list > span.weather-list-icon.wi-47 {
  background-image: asset-url('icons/weather/2.png');
}
#weather-widget .weather-list > span > i {
  line-height: 100%;
  font-size: 39px;
}
/*-------------------------
    Profile View
--------------------------*/
.profile-view {
  text-align: center;
}
.profile-view .pv-header {
  position: relative;
  height: 145px;
  width: 100%;
  background-image: asset-url('headers/sm/4.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.profile-view .pv-header > .pv-main {
  border-radius: 50%;
  width: 130px;
  position: absolute;
  height: 130px;
  bottom: -50px;
  left: 50%;
  margin-left: -65px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.profile-view .pv-body {
  margin-top: 70px;
  padding: 0 20px 20px;
}
.profile-view .pv-body > h2 {
  margin: 0;
  line-height: 100%;
  font-size: 20px;
  font-weight: 400;
}
.profile-view .pv-body > small {
  display: block;
  color: #8E8E8E;
  margin: 10px 0 15px;
}
.profile-view .pv-body .pv-contact,
.profile-view .pv-body .pv-follow {
  padding: 0;
  list-style: none;
}
.profile-view .pv-body .pv-contact > li,
.profile-view .pv-body .pv-follow > li {
  display: inline-block;
}
.profile-view .pv-body .pv-contact > li {
  margin: 0 5px;
}
.profile-view .pv-body .pv-contact > li > .zmdi {
  line-height: 100%;
  vertical-align: text-bottom;
  font-size: 22px;
}
.profile-view .pv-body .pv-follow-btn {
  padding: 7px 20px;
  background: #00BCD4;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  max-width: 200px;
  width: 100%;
  display: inline-block;
}
.profile-view .pv-body .pv-follow-btn:hover {
  background: #00a5bb;
}
.profile-view:hover .pv-main {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
/*-------------------------
    Picture List
--------------------------*/
.picture-list .pl-body {
  padding: 2px;
}
.picture-list .pl-body [class*="col-"] {
  padding: 2px;
}
.picture-list .pl-body [class*="col-"] > a {
  display: block;
}
.picture-list .pl-body [class*="col-"] > a img {
  width: 100%;
}
.picture-list .pl-body:before,
.picture-list .pl-body:after {
  content: " ";
  display: table;
}
.picture-list .pl-body:after {
  clear: both;
}
.picture-list .pl-body:before,
.picture-list .pl-body:after {
  content: " ";
  display: table;
}
.picture-list .pl-body:after {
  clear: both;
}
/*-------------------------
    Social
--------------------------*/
.go-social .card-body {
  padding: 0 15px 20px;
}
.go-social .card-body [class*="col-"] {
  padding: 12px;
}
.go-social .card-body [class*="col-"] img {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.go-social .card-body [class*="col-"]:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
/*-------------------------
    Rating
--------------------------*/
.rating-list {
  padding: 0 0 20px;
}
.rating-list .rl-star {
  margin-top: 10px;
  margin-bottom: 4px;
}
.rating-list .rl-star .zmdi {
  font-size: 20px;
  color: #ccc;
}
.rating-list .rl-star .zmdi.active {
  color: #FF9800;
}
.rating-list .media .zmdi-star {
  line-height: 100%;
  font-size: 22px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  left: 6px;
  color: #FF9800;
}
.rating-list .media .media-body {
  padding: 7px 10px 0 5px;
}
/*-------------------------
    Calendar
--------------------------*/
.cwh-year {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}
.cwh-day {
  font-size: 25px;
  line-height: 100%;
  color: #fff;
}
/*-------------------------
    Event List
--------------------------*/
.event-date {
  width: 70px;
  text-align: center;
  border-radius: 2px;
  padding: 6px 5px;
  margin-top: -3px;
  position: relative;
  color: #fff;
}
.ed-day {
  display: block;
  font-size: 20px;
  line-height: 100%;
}
.ed-month-time {
  font-size: 12px;
  display: block;
  margin-top: 1px;
  line-height: 100%;
}
.table {
  margin-bottom: 0;
}
.table > thead > tr > th {
  background-color: #fff;
  vertical-align: middle;
  font-weight: 500;
  color: #6d7074;
  border-width: 1px;
}
.table.table-inner {
  border: 0;
}
.table > thead > tr > th:first-child,
.table > tbody > tr > th:first-child,
.table > tfoot > tr > th:first-child,
.table > thead > tr > td:first-child,
.table > tbody > tr > td:first-child,
.table > tfoot > tr > td:first-child {
  padding-left: 30px;
}
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > tbody > tr.succes > td,
.table > tfoot > tr.succes > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td {
  border: 0;
}
.table-striped td,
.table-striped th {
  border: 0 !important;
}
.table-bordered {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th {
  border-bottom: 0;
  border-left: 0;
}
.table-bordered > tbody > tr > td:last-child,
.table-bordered > tbody > tr > th:last-child {
  border-right: 0;
}
.table-bordered > thead > tr > th {
  border-left: 0;
}
.table-bordered > thead > tr > th:last-child {
  border-right: 0;
}
.table-vmiddle td {
  vertical-align: middle !important;
}
.table-responsive {
  overflow-x: initial;
  border: 0;
}
#todo {
  background: #FFC107;
  color: #fff;
  font-family: 'satisfy', cursive;
}
#todo .card-header {
  padding: 28px 35px 20px;
  background-color: rgba(0, 0, 0, 0.03);
}
#todo .card-header h2 {
  font-size: 25px;
}
#todo .card-header h2 small {
  font-size: 18px;
  margin-top: 0;
}
#todo .card-body {
  font-size: 20px;
  position: relative;
}
#todo .list-group-item {
  padding: 0;
}
#todo .checkbox .input-helper:before,
#todo .checkbox .input-helper:after {
  top: 5px;
}
#todo .checkbox input:checked + i + span {
  text-decoration: line-through;
}
.t-add,
.ta-block,
.ta-btn {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.t-add {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  background: #fff;
  top: -25px;
  right: 23px;
  max-height: 300px;
}
.t-add .ta-block {
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}
.t-add .ta-block textarea {
  padding: 25px 25px 45px;
  resize: none;
  width: 100%;
  font-size: 24px;
  color: #FFC107;
  position: absolute;
  height: 100%;
  border: 0;
  outline: none;
}
.t-add:not(.toggled) {
  overflow: hidden;
}
.t-add:not(.toggled) .ta-btn {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 23px;
  color: #FF9800;
  line-height: 51px;
}
.t-add.toggled {
  width: calc(100% - 47px);
  height: calc(100% - 25px);
  border-radius: 2px;
  top: 0;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  overflow: visible;
}
.t-add.toggled .ta-btn {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  display: none;
}
.t-add.toggled .ta-block {
  opacity: 1;
  filter: alpha(opacity=100);
}
.t-add.toggled .ta-block .tab-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 10px;
  border-top: 1px solid #EEE;
  z-index: 1;
}
.t-add.toggled .ta-block .tab-actions > a {
  font-size: 25px;
  padding: 0 6px;
  border-radius: 50%;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 48px;
  display: inline-block;
}
.t-add.toggled .ta-block .tab-actions > a:hover {
  background-color: #eee;
}
.btn {
  border: 0;
  text-transform: uppercase;
}
.btn[class*="bgm-"]:not(.bgm-white) {
  color: #fff;
}
.btn .caret {
  margin-top: -3px;
}
.btn-group:not(.bootstrap-select),
.btn-group-vertical:not(.bootstrap-select) {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}
.btn-group .btn,
.btn-group-vertical .btn,
.btn-group .btn:active,
.btn-group-vertical .btn:active,
.btn-group .btn:focus,
.btn-group-vertical .btn:focus,
.btn-group .btn-group,
.btn-group-vertical .btn-group {
  box-shadow: none !important;
}
.btn-group .btn,
.btn-group-vertical .btn {
  margin: 0;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 2px 5px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-link {
  color: #797979;
  text-decoration: none;
  border-radius: 2px;
}
.btn-link:hover {
  color: #0a0a0a;
}
.btn-inverse {
  color: #fff;
  background-color: #454545;
  border-color: transparent;
}
.btn-inverse:focus,
.btn-inverse.focus {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0);
}
.btn-inverse:hover {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0);
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0);
}
.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #fff;
  background-color: #1a1a1a;
  border-color: rgba(0, 0, 0, 0);
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus {
  background-color: #454545;
  border-color: transparent;
}
.btn-inverse .badge {
  color: #454545;
  background-color: #fff;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse.focus,
.btn-inverse:active,
.open > .dropdown-toggle.btn-inverse {
  color: #fff;
  background-color: #454545;
  border-color: transparent;
}
.btn-inverse:hover:hover,
.btn-inverse:focus:hover,
.btn-inverse.focus:hover,
.btn-inverse:active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:hover:focus,
.btn-inverse:focus:focus,
.btn-inverse.focus:focus,
.btn-inverse:active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:hover.focus,
.btn-inverse:focus.focus,
.btn-inverse.focus.focus,
.btn-inverse:active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #fff;
  background-color: #454545;
  border-color: transparent;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active {
  background-color: #454545;
  border-color: transparent;
}
.btn-inverse .badge {
  color: #454545;
  background-color: #fff;
}
.btn-icon {
  border-radius: 50%;
  width: 40px;
  line-height: 42px;
  height: 40px;
  padding: 0;
  text-align: center;
}
.btn-icon .zmdi {
  font-size: 17px;
}
.btn-icon-text > .zmdi {
  font-size: 15px;
  vertical-align: top;
  display: inline-block;
  margin-top: 2px;
  line-height: 100%;
  margin-right: 5px;
}
.btn-float {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 46px !important;
}
.btn-float:not(.m-btn) {
  position: absolute !important;
}
.btn-float i {
  font-size: 23px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.btn-float:hover i {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.btn-float:not(.bgm-white):not(.bgm-gray) > i {
  color: #fff;
}
.btn-float.bgm-white > i,
.btn-float.bgm-gray > i {
  color: #333;
}
.open .btn {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
.open .btn:focus,
.open .btn:active {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
/*----------------------------------------
    Material Design Add button
------------------------------------------*/
.m-btn {
  z-index: 1;
  bottom: 40px;
  right: 40px;
  position: fixed !important;
}
label {
  font-weight: 500;
}
/*-----------------------------------
    Reset Focus and Active shadows
------------------------------------*/
input:active,
input:focus {
  outline: 0;
  box-shadow: none !important;
}
.form-control {
  box-shadow: none !important;
  resize: none;
  border-radius: 0;
}
.form-control:active,
.form-control:focus {
  box-shadow: none;
}
.form-control:not(.fc-alt) {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
}
.form-control:not(.fc-alt).auto-size {
  padding-top: 6px;
}
.form-group {
  margin-bottom: 25px;
}
/*------------------------
    Checkbox and Radio
-------------------------*/
.input-helper:before,
.input-helper:after,
.checkbox label:before,
.radio label:before,
.radio-inline:before,
.checkbox-inline:before {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.checkbox,
.radio {
  padding-top: 0 !important;
}
.checkbox label,
.radio label {
  display: block;
  padding-left: 30px;
}
.checkbox input,
.radio input {
  top: 0;
  left: 0;
  margin-left: 0 !important;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-top: 0;
}
.checkbox input:checked + .input-helper:before,
.radio input:checked + .input-helper:before {
  border-color: #607D8B;
}
.checkbox .input-helper:before,
.radio .input-helper:before,
.checkbox .input-helper:after,
.radio .input-helper:after {
  position: absolute;
  content: "";
}
.checkbox .input-helper:before,
.radio .input-helper:before {
  left: 0;
  border: 2px solid #7a7a7a;
}
.checkbox.disabled,
.radio.disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.checkbox input {
  width: 17px;
  height: 17px;
}
.checkbox input:checked + .input-helper:before {
  background-color: #607D8B;
}
.checkbox input:checked + .input-helper:after {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.checkbox .input-helper:before {
  top: 0;
  width: 17px;
  height: 17px;
  border-radius: 2px;
}
.checkbox .input-helper:after {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: '\f26b';
  font-family: 'Material-Design-Iconic-Font';
  position: absolute;
  font-size: 12px;
  left: 2px;
  top: 0;
  color: #fff;
  font-weight: bold;
}
.radio input {
  width: 19px;
  height: 19px;
}
.radio input:checked + .input-helper:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.radio .input-helper:before {
  top: -1px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
}
.radio .input-helper:after {
  width: 9px;
  height: 9px;
  background: #009688;
  border-radius: 50%;
  top: 4px;
  left: 5px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.checkbox-inline,
.radio-inline {
  vertical-align: top;
  margin-top: 0;
  padding-left: 25px;
}
.checkbox-light .input-helper:before,
.radio-light .input-helper:before {
  border-color: #fff;
}
.checkbox-light input:checked + .input-helper:before {
  border-color: #fff;
  background-color: #fff;
}
.checkbox-light input:checked + .input-helper:after {
  color: #333;
}
/*-------------------------
    Select
--------------------------*/
html:not(.ie9) .select {
  position: relative;
}
html:not(.ie9) .select:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: calc(100% - 2px);
  width: 30px;
  background-color: #FFF;
  background-position: right calc(100% - 7px);
  background-repeat: no-repeat;
  background-image: url("/img/select.png");
  pointer-events: none;
  z-index: 5;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  html:not(.ie9) .select:before {
    background-image: url("/assets/select@2x.png");
    background-size: 12px 12px;
  }
}
html:not(.ie9) .select:not(.fg-line):before {
  right: 0;
}
html:not(.ie9) .select.fg-line:before {
  right: 10px;
}
/*-------------------------
    Input Group Addon
--------------------------*/
.input-group:not(.input-group-lg):not(.input-group-sm) .input-group-addon {
  font-size: 15px;
}
.input-group-addon {
  border-width: 0px 0px 1px 0px;
  min-width: 42px;
}
.input-group-addon > .zmdi {
  position: relative;
  top: 3px;
}
/*-------------------------
    Input Feilds
--------------------------*/
.fg-line {
  position: relative;
  vertical-align: top;
}
.fg-line:not(.form-group) {
  display: inline-block;
  width: 100%;
}
.fg-line .form-control:disabled {
  color: #9d9d9d;
  background: transparent;
}
.fg-line:not(.disabled):after,
.fg-line:not(.readonly):after {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.fg-line:not([class*=has-]):after {
  background: #2196F3;
}
.fg-line.readonly .form-control {
  color: #9d9d9d;
  background: transparent;
}
.fg-line.fg-toggled:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.fg-float {
  margin-top: 2px;
  position: relative;
}
.fg-float .form-control {
  position: relative;
  background: transparent;
  z-index: 1;
}
.fg-float .form-control::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.fg-float .form-control:-ms-input-placeholder {
  color: #fff;
}
.fg-float .form-control::-webkit-input-placeholder {
  color: #fff;
}
.fg-float .fg-label {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  position: absolute;
  top: 5px;
  font-weight: 400;
  color: #959595;
  pointer-events: none;
  z-index: 0;
  left: 0;
  white-space: nowrap;
}
.fg-float .fg-toggled .fg-label {
  top: -20px;
  font-size: 11px;
}
.control-label {
  font-weight: normal;
}
/*-------------------------
    Toggle Switch
--------------------------*/
.toggle-switch {
  display: inline-block;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.toggle-switch .ts-label {
  display: inline-block;
  margin: 0 20px 0 0;
  vertical-align: top;
  -webkit-transition: color 0.56s cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 0.56s cubic-bezier(0.4, 0, 0.2, 1);
}
.toggle-switch .ts-helper {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.26);
  -webkit-transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}
.toggle-switch .ts-helper:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  width: 24px;
  height: 24px;
  background: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  webkit-transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.toggle-switch:not(.disabled) .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}
.toggle-switch input {
  position: absolute;
  z-index: 1;
  width: 46px;
  margin: 0 0 0 -4px;
  height: 24px;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.toggle-switch input:checked + .ts-helper:before {
  left: 20px;
}
.toggle-switch:not([data-ts-color]) input:not(:disabled):checked + .ts-helper {
  background: rgba(0, 150, 136, 0.5);
}
.toggle-switch:not([data-ts-color]) input:not(:disabled):checked + .ts-helper:before {
  background: #009688;
}
.toggle-switch:not([data-ts-color]) input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}
.toggle-switch.disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.toggle-switch[data-ts-color="red"] input:not(:disabled):checked + .ts-helper {
  background: rgba(241, 69, 61, 0.5);
}
.toggle-switch[data-ts-color="red"] input:not(:disabled):checked + .ts-helper:before {
  background: #f1453d;
}
.toggle-switch[data-ts-color="red"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(241, 69, 61, 0.2);
}
.toggle-switch[data-ts-color="blue"] input:not(:disabled):checked + .ts-helper {
  background: rgba(33, 150, 243, 0.5);
}
.toggle-switch[data-ts-color="blue"] input:not(:disabled):checked + .ts-helper:before {
  background: #2196F3;
}
.toggle-switch[data-ts-color="blue"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(33, 150, 243, 0.2);
}
.toggle-switch[data-ts-color="amber"] input:not(:disabled):checked + .ts-helper {
  background: rgba(255, 193, 7, 0.5);
}
.toggle-switch[data-ts-color="amber"] input:not(:disabled):checked + .ts-helper:before {
  background: #FFC107;
}
.toggle-switch[data-ts-color="amber"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(255, 193, 7, 0.2);
}
.toggle-switch[data-ts-color="purple"] input:not(:disabled):checked + .ts-helper {
  background: rgba(186, 104, 200, 0.5);
}
.toggle-switch[data-ts-color="purple"] input:not(:disabled):checked + .ts-helper:before {
  background: #BA68C8;
}
.toggle-switch[data-ts-color="purple"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(186, 104, 200, 0.2);
}
.toggle-switch[data-ts-color="pink"] input:not(:disabled):checked + .ts-helper {
  background: rgba(233, 30, 99, 0.5);
}
.toggle-switch[data-ts-color="pink"] input:not(:disabled):checked + .ts-helper:before {
  background: #E91E63;
}
.toggle-switch[data-ts-color="pink"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(233, 30, 99, 0.2);
}
.toggle-switch[data-ts-color="lime"] input:not(:disabled):checked + .ts-helper {
  background: rgba(205, 220, 57, 0.5);
}
.toggle-switch[data-ts-color="lime"] input:not(:disabled):checked + .ts-helper:before {
  background: #CDDC39;
}
.toggle-switch[data-ts-color="lime"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(205, 220, 57, 0.2);
}
.toggle-switch[data-ts-color="cyan"] input:not(:disabled):checked + .ts-helper {
  background: rgba(0, 188, 212, 0.5);
}
.toggle-switch[data-ts-color="cyan"] input:not(:disabled):checked + .ts-helper:before {
  background: #00BCD4;
}
.toggle-switch[data-ts-color="cyan"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 188, 212, 0.2);
}
.toggle-switch[data-ts-color="green"] input:not(:disabled):checked + .ts-helper {
  background: rgba(76, 175, 80, 0.5);
}
.toggle-switch[data-ts-color="green"] input:not(:disabled):checked + .ts-helper:before {
  background: #4CAF50;
}
.toggle-switch[data-ts-color="green"] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(76, 175, 80, 0.2);
}
/*-------------------------
    Validation
--------------------------*/
.has-error .checkbox .input-helper:before {
  border-color: #f8a09c;
}
.has-error .checkbox .input-helper:after {
  border-bottom-color: #f4736d;
  border-left-color: #f4736d;
}
.has-error .fg-line:after {
  background: #f1453d;
}
.has-success .checkbox .input-helper:before {
  border-color: #92cf94;
}
.has-success .checkbox .input-helper:after {
  border-bottom-color: #6ec071;
  border-left-color: #6ec071;
}
.has-success .fg-line:after {
  background: #4CAF50;
}
.has-warning .checkbox .input-helper:before {
  border-color: #ffc166;
}
.has-warning .checkbox .input-helper:after {
  border-bottom-color: #ffad33;
  border-left-color: #ffad33;
}
.has-warning .fg-line:after {
  background: #FF9800;
}
/*-------------------------
    IE 9 Placeholder
--------------------------*/
.ie9-placeholder {
  color: #888 !important;
  font-weight: normal;
}
/*-----------------------------------
    Fix Textarea Scrollbar in IE9
------------------------------------*/
.ie9 textarea {
  overflow: auto !important;
}
.pagination {
  border-radius: 0;
}
.pagination > li {
  margin: 0 2px;
  display: inline-block;
  vertical-align: top;
}
.pagination > li > a,
.pagination > li > span {
  border-radius: 50% !important;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  background-clip: padding-box;
}
.pagination > li > a > .zmdi,
.pagination > li > span > .zmdi {
  font-size: 22px;
  line-height: 39px;
}
.pagination > li.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
/*-------------------------
    Listview Pagination
--------------------------*/
.lg-pagination {
  width: 100%;
  text-align: center;
  padding: 40px 0;
  margin-top: 0;
}
/*-------------------------
    Pager
--------------------------*/
.pager li > a,
.pager li > span {
  padding: 5px 10px 6px;
  color: #7E7E7E;
}
.popover {
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
}
.popover-title {
  border-bottom: 0;
  padding: 15px;
  font-size: 12px;
  text-transform: uppercase;
}
.popover-title + .popover-content {
  padding-top: 0;
}
.popover-content {
  padding: 15px;
}
.popover-content p {
  margin-bottom: 0;
}
.fw-container .tab-content {
  padding: 25px 0;
}
.fw-container .fw-footer {
  text-align: center;
  margin: 30px 0 0;
  width: 100%;
  border-top: 2px solid #eee;
  padding: 15px 0;
}
.alert {
  padding-left: 30px;
  font-size: 13px;
}
.alert span {
  cursor: pointer;
}
.alert:not(.alert-dismissible) {
  padding-right: 30px;
}
.alert.alert-dismissable {
  padding-right: 44px;
}
.alert-inverse {
  background-color: #333;
  border-color: transparent;
  color: #fff;
}
.alert-inverse hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-inverse .alert-link {
  color: #e6e6e6;
}
.growl-animated.alert-inverse {
  box-shadow: 0 0 5px rgba(51, 51, 51, 0.5);
}
.growl-animated.alert-info {
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
}
.growl-animated.alert-success {
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}
.growl-animated.alert-warning {
  box-shadow: 0 0 5px rgba(255, 193, 7, 0.5);
}
.growl-animated.alert-danger {
  box-shadow: 0 0 5px rgba(241, 69, 61, 0.5);
}
.alert-link {
  color: #fff !important;
}
/*-------------------------
	Lightbox
-------------------------*/
.lightbox .lightbox-item {
  overflow: hidden;
}
.lightbox .lightbox-item > img {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  width: 100%;
  border-radius: 2px;
}
.lightbox .lightbox-item:hover {
  cursor: pointer;
}
.lightbox .lightbox-item:hover img {
  -webkit-transform: scale(1.35) rotate(10deg);
  -ms-transform: scale(1.35) rotate(10deg);
  -o-transform: scale(1.35) rotate(10deg);
  transform: scale(1.35) rotate(10deg);
}
.lightbox [data-src]:before,
.lightbox [data-src]:after {
  content: " ";
  display: table;
}
.lightbox [data-src]:after {
  clear: both;
}
.lightbox [data-src]:before,
.lightbox [data-src]:after {
  content: " ";
  display: table;
}
.lightbox [data-src]:after {
  clear: both;
}
.lightbox .lightbox-item:not(.p-item) {
  position: relative;
}
/*-------------------------
	Carousel
--------------------------*/
.carousel .carousel-control {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
}
.carousel .carousel-control .zmdi {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 100%;
}
@media screen and (min-width: 768px) {
  .carousel .carousel-control .zmdi {
    font-size: 60px;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
  }
}
@media screen and (max-width: 991px) {
  .carousel .carousel-control .zmdi {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
  }
}
.carousel:hover .carousel-control {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .carousel-caption {
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 50px;
}
.carousel .carousel-caption > h3 {
  color: #fff;
  margin: 0 0 5px;
  font-weight: 300;
}
.carousel .carousel-caption > p {
  margin: 0;
}
@media screen and (max-width: 991px) {
  .carousel .carousel-caption {
    display: none;
  }
}
.carousel .carousel-indicators {
  margin: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 0 6px;
  background: rgba(0, 0, 0, 0.6);
}
.carousel .carousel-indicators li {
  border-radius: 0;
  width: 15px;
  border: 0;
  background: #fff;
  height: 3px;
  margin: 0;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.carousel .carousel-indicators li.active {
  width: 25px;
  height: 3px;
  background: #FF9800;
}
@media (min-width: 768px) {
  .modal {
    text-align: center;
  }
  .modal:before {
    content: '';
    height: 100%;
    width: 1px;
    display: inline-block;
    vertical-align: middle;
  }
  .modal .modal-dialog {
    text-align: left;
    margin: 10px auto;
    display: inline-block;
    vertical-align: middle;
  }
}
.modal .modal-content {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  border: 0;
}
.modal .modal-header {
  padding: 23px 26px;
}
.modal .modal-title {
  font-weight: 400;
  font-size: 15px;
}
.modal .modal-title small {
  display: block;
  margin-top: 8px;
}
.modal .modal-body {
  padding: 0 26px 10px;
}
.modal .modal-footer .btn-link {
  font-size: 14px;
  color: #2e353b;
}
.modal .modal-footer .btn-link:hover {
  background-color: #eee;
}
.modal:not([data-modal-color]) .modal-footer .btn-link {
  font-weight: 500;
}
.modal:not([data-modal-color]) .modal-footer .btn-link:hover {
  background-color: #eee;
}
.modal[data-modal-color] {
  color: #fff;
}
.modal[data-modal-color] .modal-title,
.modal[data-modal-color] .modal-footer .btn-link {
  color: #fff;
}
.modal[data-modal-color] .modal-footer {
  background: rgba(0, 0, 0, 0.1);
}
.modal[data-modal-color] .modal-backdrop {
  background: #fff;
}
.modal[data-modal-color] .modal-footer .btn-link {
  font-weight: 400;
}
.modal[data-modal-color] .modal-footer .btn-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.modal[data-modal-color="blue"] .modal-content {
  background: #2196F3;
}
.modal[data-modal-color="cyan"] .modal-content {
  background: #00BCD4;
}
.modal[data-modal-color="green"] .modal-content {
  background: #4CAF50;
}
.modal[data-modal-color="lightgreen"] .modal-content {
  background: #8BC34A;
}
.modal[data-modal-color="lightblue"] .modal-content {
  background: #03A9F4;
}
.modal[data-modal-color="amber"] .modal-content {
  background: #FFC107;
}
.modal[data-modal-color="teal"] .modal-content {
  background: #009688;
}
.modal[data-modal-color="orange"] .modal-content {
  background: #FF9800;
}
.modal[data-modal-color="bluegray"] .modal-content {
  background: #607D8B;
}
.modal[data-modal-color="red"] .modal-content {
  background: #f1453d;
}
.panel {
  box-shadow: none;
  border: 0;
}
.panel-heading {
  padding: 0;
}
.panel-title > a {
  padding: 10px 15px;
  display: block;
  font-size: 13px;
}
.panel-collapse .panel-heading {
  position: relative;
}
.panel-collapse .panel-heading .panel-title > a {
  padding: 8px 5px 16px 30px;
  color: #000;
  position: relative;
}
.panel-collapse .panel-heading .panel-title > a:after,
.panel-collapse .panel-heading .panel-title > a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.panel-collapse .panel-heading .panel-title > a:after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.panel-collapse .panel-heading:not(.active) .panel-title > a:before {
  background: #eee;
}
.panel-collapse .panel-heading:before,
.panel-collapse .panel-heading:after {
  font-family: 'Material-Design-Iconic-Font';
  font-size: 17px;
  position: absolute;
  left: 0;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 4px;
}
.panel-collapse .panel-heading:before {
  content: "\f278";
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.panel-collapse .panel-heading:after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: "\f273";
}
.panel-collapse .panel-heading.active .panel-title > a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.panel-collapse .panel-heading.active:before {
  -webkit-transform: scale(0) rotate(-90deg);
  -ms-transform: scale(0) rotate(-90deg);
  -o-transform: scale(0) rotate(-90deg);
  transform: scale(0) rotate(-90deg);
}
.panel-collapse .panel-heading.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.panel-collapse .panel-body {
  border-top: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}
.panel-group:not([data-collapse-color]) .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #2196F3;
}
.panel-group[data-collapse-color="red"] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #f1453d;
}
.panel-group[data-collapse-color="green"] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #4CAF50;
}
.panel-group[data-collapse-color="amber"] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #FFC107;
}
.panel-group[data-collapse-color="teal"] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #009688;
}
.panel-group[data-collapse-color="black"] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #000000;
}
.panel-group[data-collapse-color="cyan"] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #00BCD4;
}
.tooltip-inner {
  border-radius: 2px;
  padding: 5px 15px 5px;
}
.breadcrumb {
  border-bottom: 1px solid #E5E5E5;
  border-radius: 0;
  margin-top: -27px;
  margin-bottom: 50px;
}
@media (min-width: 1280px) {
  .breadcrumb {
    padding: 10px 33px 11px 300px;
  }
}
@media (max-width: 1279px) {
  .breadcrumb {
    padding: 10px 33px 11px;
  }
}
.breadcrumb > li > a {
  color: #5E5E5E;
}
.breadcrumb > li > a:hover {
  color: #7c7c7c;
}
.messages {
  height: calc(100vh - 130px);
  position: relative;
  overflow: hidden;
}
.messages header {
  min-height: 56px;
}
.messages header .actions {
  position: absolute;
  top: 12px;
  right: 13px;
}
/*-----------------------------------
    Sidebar
------------------------------------*/
.m-sidebar {
  width: 300px;
  border-right: 1px solid #f3f3f3;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  /* Scrollbar fix */
}
@media (max-width: 767px) {
  .m-sidebar {
    width: 60px;
  }
  .m-sidebar .list-group-item {
    padding: 10px !important;
  }
  .m-sidebar .ms-time {
    display: none;
  }
}
.m-sidebar header {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px;
}
.m-sidebar header h2 {
  line-height: 100%;
  font-size: 15px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
}
.m-sidebar .lgi-heading {
  max-width: 120px;
}
.m-sidebar .list-group {
  height: calc(100% - 130px);
}
.m-sidebar .mCSB_scrollTools {
  z-index: 2;
}
.ms-time {
  position: absolute;
  top: 16px;
  right: 25px;
  color: #777777;
}
.ms-search {
  padding: 20px 25px;
  position: relative;
}
.ms-search .zmdi-search {
  position: absolute;
  left: 0px;
  top: 8px;
  font-size: 20px;
}
.ms-search input {
  padding-left: 25px !important;
}
/*-----------------------------------
    Body
------------------------------------*/
.m-body {
  position: relative;
  height: 100%;
}
@media (min-width: 768px) {
  .m-body {
    padding-left: 300px;
  }
}
@media (max-width: 767px) {
  .m-body {
    padding-left: 60px;
  }
}
.mb-header {
  border-bottom: 1px solid #f3f3f3;
  box-shadow: 0px -1px 0px 1px #f3f3f3;
  position: relative;
  z-index: 2;
}
.mbh-user {
  padding: 12px 20px;
}
.mbh-user > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}
.mb-list {
  height: calc(100% - 55px);
  position: relative;
}
.mbl-messages {
  padding: 50px 50px 0;
  height: calc(100vh - 259px);
  overflow-x: hidden;
}
@media (max-width: 767px) {
  .mbl-messages {
    padding: 20px 20px 0;
  }
}
.mblm-item {
  position: relative;
  margin-bottom: 10px;
}
.mblm-item > div {
  display: inline-block;
  max-width: 70%;
  padding: 15px 20px;
  border-radius: 2px;
  position: relative;
}
.mblm-item small {
  display: block;
  color: #777777;
  padding: 5px 20px;
}
.mblm-item-left > div {
  background-color: #eee;
}
.mblm-item-right {
  text-align: right;
}
.mblm-item-right > div {
  background-color: #FFC107;
  color: #fff;
}
.mblmi-img {
  margin: -10px -15px;
  cursor: pointer;
}
.mblmi-img img {
  max-width: 250px;
  border-radius: 2px;
}
.mbl-compose {
  border-top: 1px solid #eee;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 65px 15px 15px;
}
.mbl-compose textarea {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  border: 0;
  resize: none;
  background: #eee;
  padding: 5px 10px;
  color: #5E5E5E;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.mbl-compose button {
  position: absolute;
  top: 14px;
  right: 15px;
  background: transparent;
  border: 0;
  font-size: 25px;
}
.mbl-compose button:hover {
  color: #333;
}
.four-zero {
  height: 100vh;
  background-color: #607D8B;
}
.fz-block {
  background: #557382;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  margin-top: -150px;
  color: #ECF0F1;
  text-align: center;
  padding: 25px;
  height: 300px;
  width: 500px;
  left: 50%;
  margin-left: -250px;
}
.fz-block h2 {
  font-size: 130px;
  line-height: 100%;
  color: #ECF0F1;
  font-weight: 100;
}
@media (max-width: 767px) {
  .fz-block {
    width: calc(100% - 40px);
    left: 20px;
    margin-left: 0;
    height: 260px;
    margin-top: -130px;
  }
  .fz-block h2 {
    font-size: 90px;
  }
}
.fz-block small {
  display: block;
  font-size: 26px;
  margin-top: -10px;
}
.fzb-links {
  margin-top: 20px;
}
.fzb-links > a {
  font-size: 16px;
  display: inline-block;
  color: #ECF0F1;
  margin: 0 1px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.09);
  border-radius: 50%;
  text-align: center;
}
.fzb-links > a:hover {
  background: rgba(0, 0, 0, 0.2);
}
.login-content {
  min-height: 100vh;
  text-align: center;
  background-color: #009688;
}
.login-content:before {
  display: inline-block;
  content: '';
  height: 100vh;
  width: 1px;
  vertical-align: middle;
}
.lc-block {
  max-width: 370px;
  padding: 20px 0;
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
.lc-block:not(.toggled) {
  display: none;
}
.lc-block.toggled {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 10;
}
.lc-block:not(.lc-block-alt) .lcb-form {
  padding: 35px 30px 35px 30px;
}
.lc-block:not(.lc-block-alt) .btn-login {
  top: 50%;
  margin-top: -43px;
  right: -25px;
}
.lc-block .checkbox {
  text-align: left;
}
.lcb-form {
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
/*-----------------------------
    Login Navigation
------------------------------*/
.lcb-navigation {
  margin-top: 15px;
}
.lcb-navigation a,
.lcb-navigation a span {
  -webkit-transition: width;
  -o-transition: width;
  transition: width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}
.lcb-navigation a {
  color: #fff;
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 1px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  vertical-align: top;
  white-space: nowrap;
  text-align: left;
}
.lcb-navigation a i {
  width: 30px;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 30px;
}
.lcb-navigation a span {
  width: 0;
  overflow: hidden;
  display: inline-block;
  line-height: 29px;
  margin-left: -3px;
}
.lcb-navigation a:hover span {
  width: 100%;
}
.lcb-navigation a[data-ma-block="#l-register"]:hover {
  width: 95px;
}
.lcb-navigation a[data-ma-block="#l-forget-password"]:hover {
  width: 147px;
}
.lcb-navigation a[data-ma-block="#l-login"]:hover {
  width: 85px;
}
/*-----------------------------
    Lockscreen
------------------------------*/
.lc-block-alt .lcb-form {
  padding: 70px 35px 60px;
}
.lc-block-alt .btn-login {
  bottom: 0;
  left: 50%;
  margin-left: -25px;
}
.lcb-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  position: absolute;
  top: -35px;
  left: 50%;
  margin-left: -50px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.18);
}
#profile-main {
  min-height: 500px;
  position: relative;
}
@media (min-width: 1200px) {
  #profile-main .pm-overview {
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  #profile-main .pm-overview {
    width: 250px;
  }
}
@media (min-width: 768px) {
  #profile-main .pm-overview {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-right: 2px solid #eee;
  }
}
@media (max-width: 767px) {
  #profile-main .pm-overview {
    width: 100%;
    background: #333;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  #profile-main .pm-body {
    padding-left: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  #profile-main .pm-body {
    padding-left: 250px;
  }
}
@media (max-width: 767px) {
  #profile-main .pm-body {
    padding-left: 0;
  }
}
#profile-main .pmo-pic {
  position: relative;
  margin: 20px;
}
@media (min-width: 768px) {
  #profile-main .pmo-pic img {
    width: 100%;
    border-radius: 2px 2px 0 0;
  }
}
@media (max-width: 767px) {
  #profile-main .pmo-pic img {
    width: 180px;
    display: inline-block;
    height: 180px;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  }
}
#profile-main .pmo-pic .pmo-stat {
  border-radius: 0 0 2px 2px;
  color: #fff;
  text-align: center;
  padding: 30px 5px 0;
}
@media (min-width: 768px) {
  #profile-main .pmo-pic .pmo-stat {
    background: #FFC107;
    padding-bottom: 15px;
  }
}
#profile-main .pmo-pic .pmop-edit {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.38);
  text-align: center;
  padding: 10px 10px 11px;
}
#profile-main .pmo-pic .pmop-edit:hover {
  background: rgba(0, 0, 0, 0.8);
}
#profile-main .pmo-pic .pmop-edit i {
  font-size: 18px;
  vertical-align: middle;
  margin-top: -3px;
}
@media (min-width: 768px) {
  #profile-main .pmo-pic .pmop-edit {
    width: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  #profile-main .pmo-pic .pmop-edit i {
    margin-right: 4px;
  }
}
#profile-main .pmo-pic:hover .pmop-edit {
  opacity: 1;
  filter: alpha(opacity=100);
}
#profile-main .pmo-pic .pmop-message {
  position: absolute;
  bottom: 27px;
  left: 50%;
  margin-left: -25px;
}
#profile-main .pmo-pic .pmop-message .dropdown-menu {
  padding: 5px 0 55px;
  left: -90px;
  width: 228px;
  height: 150px;
  top: -74px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}
#profile-main .pmo-pic .pmop-message .dropdown-menu textarea {
  width: 100%;
  height: 95px;
  border: 0;
  resize: none;
  padding: 10px 19px;
}
#profile-main .pmo-pic .pmop-message .dropdown-menu button {
  bottom: 5px;
  left: 88px;
}
#profile-main .pmb-block {
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  #profile-main .pmb-block {
    padding: 40px 42px 0;
  }
}
@media (max-width: 1199px) {
  #profile-main .pmb-block {
    padding: 30px 20px 0;
  }
}
#profile-main .pmb-block:last-child {
  margin-bottom: 50px;
}
#profile-main .pmb-block .pmbb-header {
  margin-bottom: 25px;
  position: relative;
}
#profile-main .pmb-block .pmbb-header .actions {
  position: absolute;
  top: -2px;
  right: 0;
}
#profile-main .pmb-block .pmbb-header h2 {
  margin: 0;
  font-weight: 100;
  font-size: 20px;
}
#profile-main .pmb-block .pmbb-edit {
  position: relative;
  z-index: 1;
  display: none;
}
#profile-main .pmb-block .pmbb-edit,
#profile-main .pmb-block .pmbb-view {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
#profile-main .pmb-block.toggled .pmbb-edit {
  display: block;
}
#profile-main .pmb-block.toggled .pmbb-view {
  display: none;
}
#profile-main .pmo-block {
  padding: 25px;
}
#profile-main .pmo-block > h2 {
  font-size: 16px;
  margin: 0 0 15px;
}
#profile-main .pmo-items .pmob-body {
  padding: 0 10px;
}
#profile-main .pmo-items a {
  display: block;
  padding: 4px;
}
#profile-main .pmo-items a img {
  width: 100%;
}
.pmo-contact ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pmo-contact ul li {
  position: relative;
  padding: 8px 0 8px 35px;
}
.pmo-contact ul li i {
  font-size: 18px;
  vertical-align: top;
  line-height: 100%;
  position: absolute;
  left: 0;
  width: 18px;
  text-align: center;
}
.pmo-map {
  margin: 20px -21px -18px;
  display: block;
}
.pmo-map img {
  width: 100%;
}
@media (max-width: 767px) {
  .c-timeline {
    background: #f3f3f3;
    box-shadow: none;
  }
  .c-timeline .tab-nav {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }
}
.timeline {
  position: relative;
}
@media (min-width: 768px) {
  .timeline {
    padding: 50px;
    padding-left: 100px;
  }
}
@media (max-width: 767px) {
  .timeline {
    margin-top: 30px;
  }
}
.t-view {
  border: 1px solid #eee;
  position: relative;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .t-view {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }
}
.t-view .tv-header {
  padding: 16px 18px;
  border-bottom: 1px solid #eee;
  background: #F9F9F9;
}
.t-view .tv-header .actions {
  position: absolute;
  top: 5px;
  right: 10px;
}
.t-view .tv-body {
  padding: 23px 25px;
}
.t-view .tv-body .tvb-lightbox {
  margin: 0 -8px 15px;
}
.t-view .tv-body .tvb-lightbox [data-src] {
  padding: 0 5px;
  margin-bottom: 5px;
}
.t-view .tvh-user {
  display: block;
}
.t-view .tvh-user img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
.t-view:before {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: -70px;
  top: 0;
  border: 3px solid #FFF;
  text-align: center;
  font-size: 16px;
  line-height: 34px;
  color: #FFF;
  font-family: 'Material-Design-Iconic-Font';
  z-index: 1;
}
.t-view:after {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 1px;
  height: calc(100% + 37px);
}
.t-view[data-tv-type="text"]:before {
  content: "\f24f";
  background: #00BCD4;
  box-shadow: 0 0 0 1px #00BCD4;
}
.t-view[data-tv-type="text"]:after {
  background: #00BCD4;
}
.t-view[data-tv-type="image"]:before {
  content: "\f17f";
  background: #4CAF50;
  box-shadow: 0 0 0 1px #4CAF50;
}
.t-view[data-tv-type="image"]:after {
  background: #4CAF50;
}
.t-view[data-tv-type="video"]:before {
  content: "\f3a9";
  background: #FFC107;
  box-shadow: 0 0 0 1px #FFC107;
}
.t-view[data-tv-type="video"]:after {
  background: #FFC107;
}
.t-view .tvb-stats {
  list-style: none;
  padding: 0;
  margin: 10px 0 20px;
}
.t-view .tvb-stats > li {
  display: inline-block;
  padding: 5px 10px 6px;
  border: 1px solid #ccc;
  margin-right: 2px;
}
.t-view .tvb-stats > li i {
  font-size: 15px;
  line-height: 100%;
  vertical-align: top;
  margin-top: 2px;
}
.t-view .tvb-stats > li.tvbs-comments {
  border-color: #4CAF50;
  color: #4CAF50;
}
.t-view .tvb-stats > li.tvbs-likes {
  border-color: #03A9F4;
  color: #03A9F4;
}
.t-view .tvb-stats > li.tvbs-views {
  border-color: #FF9800;
  color: #FF9800;
}
.tv-comments .tvc-lists {
  padding: 0;
  list-style: none;
  margin: 0;
}
.tv-comments .tvc-lists > li {
  padding: 15px 20px;
  margin: 0;
  border-top: 1px solid #eee;
}
.tvc-more {
  color: #333;
  display: block;
  margin-bottom: -10px;
}
.tvc-more:hover {
  color: #000;
}
.tvc-more i {
  vertical-align: middle;
  margin-right: 5px;
}
.p-header {
  position: relative;
  margin: 0 -7px;
}
.p-header .actions {
  position: absolute;
  top: -18px;
  right: 0;
}
.p-menu {
  list-style: none;
  padding: 0 5px;
  margin: 0 0 30px;
}
.p-menu > li {
  display: inline-block;
  vertical-align: top;
}
.p-menu > li > a {
  display: block;
  padding: 5px 20px 5px 0;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
}
.p-menu > li > a > i {
  margin-right: 4px;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
}
.p-menu > li:not(.active) > a {
  color: #4285F4;
}
.p-menu > li:not(.active) > a:hover {
  color: #333;
}
.p-menu > li.active > a {
  color: #000;
}
@media (max-width: 991px) {
  .p-menu .pm-search {
    margin: 20px 2px 30px;
    display: block;
  }
  .p-menu .pm-search input[type="text"] {
    width: 100%;
    border: 1px solid #ccc;
  }
}
.p-menu .pms-inner {
  margin: -2px 0 0;
  position: relative;
  top: -2px;
  overflow: hidden;
  white-space: nowrap;
}
.p-menu .pms-inner i {
  vertical-align: top;
  font-size: 20px;
  line-height: 100%;
  position: absolute;
  left: 9px;
  top: 8px;
  color: #333;
}
.p-menu .pms-inner input[type="text"] {
  height: 35px;
  border-radius: 2px;
  padding: 0 10px 0 40px;
}
@media (min-width: 768px) {
  .p-menu .pms-inner input[type="text"] {
    border: 1px solid #fff;
    width: 50px;
    background: transparent;
    position: relative;
    z-index: 1;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
  }
  .p-menu .pms-inner input[type="text"]:focus {
    border-color: #DFDFDF;
    width: 200px;
  }
}
.photos {
  margin: 2px 0 0;
}
.photos .lightbox {
  margin: 0 -8px;
}
.photos:not(.p-timeline) [data-src] {
  padding: 3px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
}
.p-timeline {
  position: relative;
  padding-left: 80px;
  margin-bottom: 75px;
}
.p-timeline [data-src] {
  float: left;
  width: 70px;
  height: 70px;
  margin: 0 3px 3px 0;
}
.p-timeline:last-child .pt-line:before {
  height: 100%;
}
.ptb-title {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}
.pt-line {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 14px;
}
.pt-line:before,
.pt-line:after {
  content: "";
  position: absolute;
}
.pt-line:before {
  width: 1px;
  height: calc(100% + 63px);
  background: #E2E2E2;
  top: 14px;
  right: -20px;
}
.pt-line:after {
  top: 2px;
  right: -26px;
  width: 13px;
  height: 13px;
  border: 1px solid #C1C1C1;
  border-radius: 50%;
}
.contacts:not(.c-profile) {
  padding: 0 8px;
}
.contacts > [class*="col-"] {
  padding: 0 10px;
}
.contacts .c-item {
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  margin-bottom: 24px;
}
.contacts .c-item .ci-avatar {
  display: block;
}
.contacts .c-item .ci-avatar img {
  width: 100%;
  border-radius: 2px 2px 0 0;
}
.contacts .ci-avatar {
  margin: -1px -1px 0;
}
.contacts .c-info {
  text-align: center;
  margin-top: 15px;
  padding: 0 5px;
}
.contacts .c-info strong {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.contacts .c-info small {
  color: #999;
  margin-top: 3px;
}
.contacts .c-info strong,
.contacts .c-info small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.contacts .c-footer {
  border-top: 1px solid #e2e2e2;
  margin-top: 18px;
}
.contacts .c-footer > button {
  padding: 7px 10px;
  color: #333;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  border-radius: 2px;
  background: #fff;
  border: 0;
}
.contacts .c-footer > button > i {
  font-size: 16px;
  vertical-align: middle;
  margin-top: -3px;
}
/*--------------------------------------------------
    Block Header
    Used for Heading outside the Cards.
---------------------------------------------------*/
.block-header {
  margin-bottom: 25px;
  position: relative;
}
.block-header > h2 {
  font-size: 19px;
  color: #333;
  margin: 0;
  font-weight: 400;
  float: left;
}
.block-header > h2 > small {
  display: block;
  text-transform: none;
  margin-top: 8px;
  margin-bottom: 20px;
  color: #9E9E9E;
  line-height: 140%;
}
.block-header .actions {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 4;
}
/*-------------------------
    Actions
--------------------------*/
.actions {
  list-style: none;
  padding: 0;
  z-index: 3;
  margin: 0;
}
.actions > li {
  display: inline-block;
  vertical-align: baseline;
}
.actions > li > a,
.actions > a,
span.no-link {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}
.actions > li > a > i,
.actions > a > i {
  color: #adadad;
  font-size: 20px;
}
.actions > li > a:hover > i,
.actions > a:hover > i {
  color: #000;
}
.actions > li.open > a > i,
.actions.open > a > i {
  color: #000;
}
.actions > li.open > a:before,
.actions.open > a:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
}
.actions.actions-alt > li > a > i {
  color: #fff;
}
.actions.actions-alt > li > a > i:hover {
  color: #fff;
}
.actions.actions-alt > li.open > a > i {
  color: #fff;
}
.actions.open {
  z-index: 4;
}
/*-------------------------
    Collapse Menu Icons
--------------------------*/
.line-wrap {
  width: 18px;
  height: 12px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  margin: 12px 20px;
}
.line-wrap .line {
  width: 18px;
  height: 2px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  background-color: #fff;
}
.line-wrap .line.center {
  margin: 3px 0;
}
.toggled .line-wrap {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.toggled .line-wrap .line.top {
  width: 12px;
  transform: translateX(8px) translateY(1px) rotate(45deg);
  -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
}
.toggled .line-wrap .line.bottom {
  width: 12px;
  transform: translateX(8px) translateY(-1px) rotate(-45deg);
  -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
}
/*-------------------------
    Load More
--------------------------*/
.load-more {
  text-align: center;
  margin-top: 30px;
}
.load-more a {
  padding: 8px 10px 6px;
  display: inline-block;
  background-color: #f1453d;
  color: #FFF;
  border-radius: 2px;
  white-space: nowrap;
}
.load-more a i {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  margin-top: -2px;
}
.load-more a:hover {
  background-color: #ea1b11;
}
/*-------------------------
    View More
--------------------------*/
.view-more {
  display: block;
  text-align: center;
  padding: 10px 10px 15px;
  line-height: 100%;
  font-size: 11px;
  margin-top: 10px;
  text-transform: uppercase;
  color: #777777;
}
.view-more:hover {
  color: #5E5E5E;
}
/*-------------------------
    Page Loader
--------------------------*/
html:not(.ismobile) .page-loader {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
html:not(.ismobile) .page-loader .preloader {
  width: 50px;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -55px;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 3000ms;
  animation-duration: 3000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
html:not(.ismobile) .page-loader .preloader p {
  white-space: nowrap;
  position: relative;
  left: -9px;
  top: 22px;
  color: #CCC;
}
html.ismobile .page-loader {
  display: none;
}
/*----------------------------------------
    Action Header
-----------------------------------------*/
.action-header {
  padding: 25px 30px;
  line-height: 100%;
  position: relative;
  z-index: 1;
  min-height: 65px;
  border-bottom: 1px solid #F7F7F7;
}
.action-header .actions {
  position: absolute;
  top: 18px;
  right: 17px;
  z-index: 10;
}
.ah-label {
  font-size: 15px;
}
.ah-search {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  background-color: #454E53;
  display: none;
}
.ahs-input {
  border: 0;
  padding: 0 26px;
  height: 100%;
  font-size: 15px;
  width: 100%;
  line-height: 60px;
  background-color: #F7F7F7;
}
.ahs-close {
  font-style: normal;
  position: absolute;
  top: 23px;
  right: 25px;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  cursor: pointer;
  background: #E2E2E2;
  width: 18px;
  height: 18px;
  color: #6B6B6B;
  border-radius: 50%;
}
.ahs-close:hover {
  opacity: 0.8;
}
/*----------------------------------
    Backdrop
-----------------------------------*/
.ma-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  cursor: pointer;
}
/*----------------------------------
    Avatar
-----------------------------------*/
[class*="avatar-img"] {
  border-radius: 50%;
}
.avatar-img {
  width: 42px;
}
/*----------------------------------
    Stats Arrows
-----------------------------------*/
.stats-arrow {
  width: 21px;
  height: 21px;
  border: 1px solid #eee;
  border-radius: 50%;
  text-align: center;
  vertical-align: text-top;
  margin-top: -3px;
  position: relative;
  top: 2px;
  margin-left: 5px;
  line-height: 20px;
}
.stats-arrow-up {
  color: #4CAF50;
  border-color: #6ec071;
}
.stats-arrow-down {
  color: #f1453d;
  border-color: #f4736d;
}
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}
.ie-warning p {
  font-size: 17px;
}
.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}
.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}
.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}
.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}
.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}
.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}
#footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 110px;
  color: #a2a2a2;
  padding-top: 35px;
  padding-bottom: 15px;
}
@media (min-width: 1280px) {
  #footer {
    padding-left: 268px;
  }
}
#footer .f-menu {
  display: block;
  width: 100%;
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
  margin-top: 8px;
}
#footer .f-menu > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
#footer .f-menu > li > a {
  color: #a2a2a2;
}
#footer .f-menu > li > a:hover {
  color: #777;
}
.sidebar-toggled #footer {
  display: none;
}
.pt-inner {
  text-align: center;
}
.pt-inner .pti-header {
  padding: 45px 10px 70px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
}
.pt-inner .pti-header > h2 {
  margin: 0;
  line-height: 100%;
  color: #fff;
  font-weight: 100;
  font-size: 50px;
}
.pt-inner .pti-header > h2 small {
  color: #fff;
  letter-spacing: 0;
  vertical-align: top;
  font-size: 16px;
  font-weight: 100;
}
.pt-inner .pti-header .ptih-title {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 8px 10px 9px;
  text-transform: uppercase;
  margin: 0 -10px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.pt-inner .pti-body {
  padding: 0 23px;
}
.pt-inner .pti-body .ptib-item {
  padding: 15px 0;
  font-weight: 400;
}
.pt-inner .pti-body .ptib-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.pt-inner .pti-footer {
  padding: 10px 20px 30px;
}
.pt-inner .pti-footer > a {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  display: inline-block;
  line-height: 60px;
  font-size: 30px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.pt-inner .pti-footer > a:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}
.invoice {
  min-width: 1100px;
  max-width: 1170px;
}
.i-logo {
  width: 150px;
}
.i-table .highlight {
  background-color: #eee;
  border-bottom: 1px solid #e6e6e6;
}
.i-table td.highlight {
  font-size: 14px;
  font-weight: 500;
}
/*-------------------------------
    Post
--------------------------------*/
.wp-text {
  border: 0;
  display: block;
  width: 100%;
  resize: none !important;
  padding: 20px 25px;
}
.wp-media {
  background: #F7F7F7;
  border: 1px solid #E4E4E4;
  padding: 12px 15px;
  margin: 10px 20px 20px;
  text-align: center;
}
.wp-actions {
  padding: 10px 15px 10px 20px;
  background-color: #F7F7F7;
}
.wpa-media-list > a {
  font-size: 20px;
  margin-right: 8px;
}
.wpa-media-list > a:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/*-------------------------------
    Post Listings
--------------------------------*/
.wis-numbers {
  float: left;
}
.wis-numbers > span {
  margin-right: -1px;
  padding: 7px 12px;
  border: 1px solid #E0E0E0;
  float: left;
  font-weight: 500;
}
.wis-numbers > span > i {
  line-height: 100%;
  vertical-align: top;
  position: relative;
  top: 3px;
  font-size: 15px;
  margin-right: 2px;
}
.wis-numbers > span.active {
  color: #4CAF50;
}
.wis-commentors {
  float: right;
}
.wis-commentors > a {
  display: inline-block;
  margin-left: 2px;
}
.wis-commentors > a > img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
.wis-commentors > a > img:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
}
/*-------------------------------
   Post Gallery
--------------------------------*/
.wi-preview {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .wi-preview {
    margin: 0 -23px 20px;
  }
}
@media screen and (max-width: 991px) {
  .wi-preview {
    margin: 0 -16px 20px;
  }
}
.wi-preview .wip-item {
  display: block;
  float: left;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.wi-preview .wip-item:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.wi-preview .wip-item > img {
  display: none;
}
.wi-preview .wip-item:first-child:nth-last-child(2),
.wi-preview .wip-item:first-child:nth-last-child(2) ~ div {
  width: 50%;
  padding-bottom: 40%;
}
.wi-preview .wip-item:first-child:nth-last-child(3),
.wi-preview .wip-item:first-child:nth-last-child(3) ~ div,
.wi-preview .wip-item:first-child:nth-last-child(4),
.wi-preview .wip-item:first-child:nth-last-child(4) ~ div:not(:last-child),
.wi-preview .wip-item:first-child:nth-last-child(5),
.wi-preview .wip-item:first-child:nth-last-child(5) ~ div:not(:nth-last-of-type(-n+2)),
.wi-preview .wip-item:first-child:nth-last-child(6),
.wi-preview .wip-item:first-child:nth-last-child(6) ~ div,
.wi-preview .wip-item:first-child:nth-last-child(7) ~ div:nth-last-of-type(-n+3) {
  width: 33.333333%;
  padding-bottom: 30%;
}
.wi-preview .wip-item:first-child:nth-last-child(5) ~ div:nth-last-of-type(-n+2) {
  width: 50%;
  padding-bottom: 40%;
}
.wi-preview .wip-item:first-child:nth-last-child(7),
.wi-preview .wip-item:first-child:nth-last-child(7) ~ div:not(:nth-last-of-type(-n+3)),
.wi-preview .wip-item:first-child:nth-last-child(n+8),
.wi-preview .wip-item:first-child:nth-last-child(n+8) ~ div {
  width: 25%;
  padding-bottom: 22%;
}
.wi-preview .wip-item:only-child,
.wi-preview .wip-item:first-child:nth-last-child(4) ~ div:nth-child(4) {
  width: 100%;
  padding-bottom: 50%;
}
/*-------------------------------
   Post Comments
--------------------------------*/
.wi-comments {
  background: #F7F7F7;
}
.wi-comments .list-group {
  margin-bottom: -10px;
  padding-top: 10px;
}
.wic-form {
  padding: 20px 23px;
}
.wic-form textarea {
  width: 100%;
  resize: none;
  border: 1px solid #E8E8E8;
  padding: 12px 15px;
  height: 45px;
}
.wic-form.toggled textarea {
  height: auto;
}
.wic-form.toggled .wicf-actions {
  display: block;
}
.wicf-actions {
  margin-top: 10px;
  display: none;
}
/*----------------------------------------------------------
    For header type 1 only
    You may remove these if you opt header 2
-----------------------------------------------------------*/
#header .skin-switch {
  padding: 10px 0 2px;
  text-align: center;
}
#header .ss-skin {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin: 2px 3px;
}
/* ----------------------------- End header type 1 ------------------------------------- */
/*----------------------------------------------------------
    For header type 2 only
    You may remove these if you opt header 1
-----------------------------------------------------------*/
@media (min-width: 992px) {
  #header-alt .skin-switch {
    position: absolute;
    right: 50px;
    bottom: 23px;
    z-index: 1;
  }
  #header-alt .skin-switch .btn {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 25px;
    z-index: 2;
  }
  #header-alt .skin-switch .dropdown-menu {
    display: block;
    min-width: 130px;
    height: 130px;
    border-radius: 50%;
    width: 130px;
    top: -42px;
    left: -40px;
    z-index: 1;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: scale(0) rotate(-360deg);
    -ms-transform: scale(0) rotate(-360deg);
    -o-transform: scale(0) rotate(-360deg);
    transform: scale(0) rotate(-360deg);
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin {
    position: absolute;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-1 {
    margin-left: -8px;
    top: 12px;
    left: 50%;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-2 {
    right: 24px;
    top: 26px;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-3 {
    top: 50%;
    margin-top: -8px;
    right: 12px;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-4 {
    right: 24px;
    bottom: 26px;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-5 {
    margin-left: -8px;
    bottom: 12px;
    left: 50%;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-6 {
    left: 24px;
    bottom: 26px;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-7 {
    top: 50%;
    margin-top: -8px;
    left: 12px;
  }
  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-8 {
    left: 24px;
    top: 26px;
  }
  #header-alt .skin-switch.open .dropdown-menu {
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}
@media (max-width: 991px) {
  #header-alt .skin-switch {
    display: none;
  }
}
/* ----------------------------- End header type 2 ------------------------------------- */
/*-----------------------------------------------------------
    Do not remove these
    This is common for both
-----------------------------------------------------------*/
.ss-skin {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
}
.ss-skin:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
[data-ma-theme="lightblue"] {
  background-color: #03A9F4;
}
[data-ma-theme="lightblue"] .ss-icon {
  color: #03A9F4;
}
@media (max-width: 767px) {
  [data-ma-theme="lightblue"] .ha-menu {
    background: #03A9F4;
  }
}
[data-ma-theme="bluegray"] {
  background-color: #607D8B;
}
[data-ma-theme="bluegray"] .ss-icon {
  color: #607D8B;
}
@media (max-width: 767px) {
  [data-ma-theme="bluegray"] .ha-menu {
    background: #607D8B;
  }
}
[data-ma-theme="blue"] {
  background-color: #2196F3;
}
[data-ma-theme="blue"] .ss-icon {
  color: #2196F3;
}
@media (max-width: 767px) {
  [data-ma-theme="blue"] .ha-menu {
    background: #2196F3;
  }
}
[data-ma-theme="purple"] {
  background-color: #BA68C8;
}
[data-ma-theme="purple"] .ss-icon {
  color: #BA68C8;
}
@media (max-width: 767px) {
  [data-ma-theme="purple"] .ha-menu {
    background: #BA68C8;
  }
}
[data-ma-theme="orange"] {
  background-color: #FF9800;
}
[data-ma-theme="orange"] .ss-icon {
  color: #FF9800;
}
@media (max-width: 767px) {
  [data-ma-theme="orange"] .ha-menu {
    background: #FF9800;
  }
}
[data-ma-theme="cyan"] {
  background-color: #00BCD4;
}
[data-ma-theme="cyan"] .ss-icon {
  color: #00BCD4;
}
@media (max-width: 767px) {
  [data-ma-theme="cyan"] .ha-menu {
    background: #00BCD4;
  }
}
[data-ma-theme="green"] {
  background-color: #4CAF50;
}
[data-ma-theme="green"] .ss-icon {
  color: #4CAF50;
}
@media (max-width: 767px) {
  [data-ma-theme="green"] .ha-menu {
    background: #4CAF50;
  }
}
[data-ma-theme="teal"] {
  background-color: #009688;
}
[data-ma-theme="teal"] .ss-icon {
  color: #009688;
}
@media (max-width: 767px) {
  [data-ma-theme="teal"] .ha-menu {
    background: #009688;
  }
}
[data-ma-theme="pink"] {
  background-color: #E91E63;
}
[data-ma-theme="pink"] .ss-icon {
  color: #E91E63;
}
@media (max-width: 767px) {
  [data-ma-theme="pink"] .ha-menu {
    background: #E91E63;
  }
}
.preloader {
  position: relative;
  margin: 0px auto;
  display: inline-block;
}
.preloader:not([class*="pl-"]) {
  width: 40px;
}
.preloader:before {
  content: '';
  display: block;
  padding-top: 100%;
}
.preloader.pl-xs {
  width: 20px;
}
.preloader.pl-sm {
  width: 30px;
}
.preloader.pl-lg {
  width: 50px;
}
.preloader.pl-xl {
  width: 80px;
}
.preloader.pl-xxl {
  width: 100px;
}
.preloader:not([class*="pls-"]) .plc-path {
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
}
.preloader[class*="pls-"] .plc-path {
  animation: dash 1.5s ease-in-out infinite;
}
.preloader.pls-red .plc-path {
  stroke: #f1453d;
}
.preloader.pls-blue .plc-path {
  stroke: #2196F3;
}
.preloader.pls-green .plc-path {
  stroke: #4CAF50;
}
.preloader.pls-yellow .plc-path {
  stroke: #607d8b;
}
.preloader.pls-bluegray .plc-path {
  stroke: #607D8B;
}
.preloader.pls-amber .plc-path {
  stroke: #FFC107;
}
.preloader.pls-teal .plc-path {
  stroke: #009688;
}
.preloader.pls-gray .plc-path {
  stroke: #9E9E9E;
}
.preloader.pls-pink .plc-path {
  stroke: #E91E63;
}
.preloader.pls-purple .plc-path {
  stroke: #BA68C8;
}
.preloader.pls-white .plc-path {
  stroke: #fff;
}
.pl-circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.plc-path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #f1453d;
  }
  40% {
    stroke: #2196F3;
  }
  66% {
    stroke: #4CAF50;
  }
  80%,
  90% {
    stroke: #FFC107;
  }
}
/*------------------------------
    Next/Prev Months
-------------------------------*/
.actions-calendar .calendar-next > i,
.actions-calendar .calendar-prev > i {
  font-size: 25px;
  position: relative;
  top: 2px;
}
/*------------------------------
    Tag color
-------------------------------*/
.event-tag > span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 0 3px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.event-tag > span.color-tag__default {
  border: 1px solid #eee;
  background-color: #fff !important;
}
.event-tag > span.color-tag__default > i:before {
  color: #333;
}
.event-tag > span,
.event-tag > span > i {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}
.event-tag > span > input[type=radio] {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.event-tag > span > input[type=radio]:checked + i {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.event-tag > span:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.event-tag > span > i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 4px 0 0 7px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.event-tag > span > i:before {
  content: '\f26b';
  font-family: 'Material-Design-Iconic-Font';
  color: #fff;
  font-size: 16px;
  z-index: 1;
}
/*-------------------------------------
    Main Calendar Seasonal headers
--------------------------------------*/
[data-calendar-month] {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
[data-calendar-month="0"] {
  background-image: asset-url('calendar/january.jpg');
}
[data-calendar-month="1"] {
  background-image: asset-url('calendar/february.jpg');
}
[data-calendar-month="2"] {
  background-image: asset-url('calendar/march.jpg');
}
[data-calendar-month="3"] {
  background-image: asset-url('calendar/april.jpg');
}
[data-calendar-month="4"] {
  background-image: asset-url('calendar/may.jpg');
}
[data-calendar-month="5"] {
  background-image: asset-url('calendar/june.jpg');
}
[data-calendar-month="6"] {
  background-image: asset-url('calendar/july.jpg');
}
[data-calendar-month="7"] {
  background-image: asset-url('calendar/august.jpg');
}
[data-calendar-month="8"] {
  background-image: asset-url('calendar/september.jpg');
}
[data-calendar-month="9"] {
  background-image: asset-url('calendar/october.jpg');
}
[data-calendar-month="10"] {
  background-image: asset-url('calendar/november.jpg');
}
[data-calendar-month="11"] {
  background-image: asset-url('calendar/december.jpg');
}
@media print {
  @page {
    margin: 0;
    size: auto;
  }
  body {
    margin: 0mm 0mm 0mm 0mm !important;
    padding: 0mm !important;
  }
  #header,
  #footer,
  #sidebar,
  #chat,
  .growl-animated,
  .m-btn {
    display: none !important;
  }
  /*-------------------------
        Invoice
     --------------------------*/
  .invoice {
    padding: 30px !important;
    -webkit-print-color-adjust: exact !important;
  }
  .invoice .card-header {
    background: #eee !important;
    padding: 20px;
    margin-bottom: 20px;
    margin: -60px -30px 25px -30px;
  }
  .invoice .block-header {
    display: none;
  }
  .invoice .highlight {
    background: #eee !important;
  }
}
.visitors-stats {
  margin: 0 -6px 30px;
}
.visitors-stats .col-xs-6 {
  padding: 0 6px;
}
.visitors-stats-item {
  border: 1px solid #eee;
  padding: 13px 17px 12px;
  border-radius: 2px;
}
.visitors-stats-item strong {
  font-size: 25px;
  font-weight: normal;
  color: #333;
  line-height: 100%;
}
.visitors-stats-item small {
  display: block;
  margin-top: 3px;
  color: #777777;
}
.analytics-img-country {
  height: 13px;
  width: 18px;
  vertical-align: top;
  position: relative;
  top: 2px;
  margin-right: 5px;
  left: -1px;
  border-radius: 1px;
}
.analytics-img-browser {
  width: 20px;
  vertical-align: top;
  margin: -2px 10px 0 0;
  top: 1px;
  position: relative;
}
/*
 * Vendor Overrides
 */
.mejs-container {
  outline: none;
}
.mejs-container .mejs-controls {
  background: #ec592f;
  height: 50px;
  padding: 10px 5px 0;
}
.mejs-container .mejs-controls div {
  height: 5px;
}
.mejs-container .mejs-controls div.mejs-time-rail {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  width: 100% !important;
}
.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-total {
  margin: 0;
  width: 100% !important;
  background: #ec592f;
}
.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-loaded {
  background: #D04B25;
}
.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-current {
  background: #ffea00;
}
.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-buffering {
  background: #ec592f;
}
.mejs-container .mejs-controls div.mejs-time-rail span:not(.mejs-time-float),
.mejs-container .mejs-controls div.mejs-time-rail a {
  border-radius: 0;
  height: 3px;
}
.mejs-container .mejs-controls .mejs-button button {
  background-color: #ec592f;
  width: 15px;
  height: 15px;
  background-position: center;
}
.mejs-container .mejs-controls .mejs-button button:focus {
  outline: none !important;
}
.mejs-container .mejs-controls .mejs-volume-button {
  position: absolute;
  right: 35px;
}
.mejs-container .mejs-controls .mejs-play button {
  background-image: url("/assets/icons/play.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-play button {
    background-image: url("/assets/icons/play@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-pause button {
  background-image: url("/assets/icons/pause.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-pause button {
    background-image: url("/assets/icons/pause@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-mute button {
  background-image: url("/assets/icons/speaker.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-mute button {
    background-image: url("/assets/icons/speaker@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-unmute button {
  background-image: url("/assets/icons/speaker-2.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-unmute button {
    background-image: url("/assets/icons/speaker-2@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-fullscreen-button {
  position: absolute;
  right: 5px;
}
.mejs-container .mejs-controls .mejs-fullscreen-button button {
  background-image: url("/assets/icons/fullscreen.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-fullscreen-button button {
    background-image: url("/assets/icons/fullscreen@2x.png");
    background-size: 15px 15px;
  }
}
.fc th {
  font-weight: 500;
  padding: 12px 12px 10px;
  text-transform: uppercase;
}
.fc table {
  background: transparent;
}
.fc table tr > td:first-child {
  border-left-width: 0;
}
.fc button {
  border: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
  font-size: 20px;
  line-height: 28px;
  display: block;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  color: #333;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.fc button:hover {
  background-color: #eee;
}
.fc div.fc-row {
  margin-right: 0;
  border: 0;
}
.fc-unthemed .fc-today {
  color: #FFC107;
  background-color: transparent;
}
.fc-toolbar {
  margin-bottom: 0;
  padding: 15px 20px;
  position: relative;
}
.fc-toolbar h2 {
  margin-top: 7px;
}
.fc-event {
  padding: 0;
  font-size: 11px;
  border-radius: 2px;
  border: 0;
}
.fc-event .fc-title {
  padding: 2px 8px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fc-event .fc-time {
  float: left;
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  margin: 0 0 0 -1px;
}
.fc-view,
.fc-view > table {
  border: 0;
  overflow: hidden;
}
.fc-view > table > tbody > tr .ui-widget-content {
  border-top: 0;
}
hr.fc-divider {
  border-width: 1px;
  border-color: #eee;
}
.fc-day-grid-container.fc-scroller {
  height: auto !important;
  overflow: hidden !important;
}
/*----------------------
    Main Calendar
-----------------------*/
#calendar {
  z-index: 0;
}
#calendar td,
#calendar th {
  border-color: #f5f5f5;
}
#calendar .fc-toolbar {
  height: 250px;
  background-color: #fff;
  border-radius: 2px 2px 0 0;
  position: relative;
  margin-bottom: -2px;
  z-index: 2;
}
@media (max-width: 767px) {
  #calendar .fc-toolbar {
    height: 135px;
  }
}
#calendar .fc-day-number {
  padding-left: 10px;
  color: #ccc;
  text-align: left;
  float: left;
}
@media screen and (min-width: 991px) {
  #calendar .fc-day-number {
    font-size: 20px;
    letter-spacing: -2px;
  }
}
#calendar .fc-day-header {
  text-align: left;
}
#calendar .fc-day-grid-event {
  margin: 1px 9px 0;
}
/*----------------------
    Calendar Widget
-----------------------*/
#calendar-widget td,
#calendar-widget th {
  border-color: transparent;
}
#calendar-widget .fc-toolbar h2 {
  font-size: 16px;
}
#calendar-widget .fc-day-number {
  text-align: center;
  width: 100%;
}
#calendar-widget .fc-day-grid-event {
  margin: 1px 3px 1px;
}
#calendar-widget .ui-widget-header th,
#calendar-widget .ui-widget-header {
  border-width: 0;
}
table.dataTable {
  margin: 20px 0;
}
table.dataTable thead [class*="sorting"] {
  background-position: center right !important;
}
table.dataTable thead td {
  background: transparent !important;
  white-space: nowrap;
}

table.dataTable thead span.sort-icon {
  display: inline-block;
  position: relative;
  top: 4px;
  left: 6px;
  width: 16px;
  height: 16px;
}

table.dataTable thead .sorting span { background: url('http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_both.png') no-repeat center right; }
table.dataTable thead .sorting_asc span { background: url('http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_asc.png') no-repeat center right; }
table.dataTable thead .sorting_desc span { background: url('http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_desc.png') no-repeat center right; }

table.dataTable thead .sorting_asc_disabled span { background: url('http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_asc_disabled.png') no-repeat center right; }
table.dataTable thead .sorting_desc_disabled span { background: url('http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_desc_disabled.png') no-repeat center right; }

table.dataTable.no-footer {
  border-bottom: 1px solid #F5F5F5;
}
.dataTables_length,
.dataTables_filter {
  padding: 20px 30px;
}
.dataTables_length label,
.dataTables_filter label {
  font-weight: normal;
}
.dataTables_length input,
.dataTables_filter input,
.dataTables_length select,
.dataTables_filter select {
  border: 1px solid #eee;
  height: 35px;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 100%;
  background-color: #fff;
}
.dataTables_length select {
  margin: 0 8px;
}
@media (max-width: 767px) {
  .dataTables_length {
    display: none;
  }
}
.dataTables_filter label {
  position: relative;
  font-size: 0;
}
.dataTables_filter label:after {
  content: '\f1c3';
  font-family: 'Material-Design-Iconic-Font';
  position: absolute;
  font-size: 18px;
  right: 15px;
  top: 5px;
}
.dataTables_filter label .form-control {
  padding-right: 35px;
  width: 100% !important;
  margin: 0 !important;
}
@media (max-width: 767px) {
  .dataTables_filter label {
    width: 100%;
  }
}
.dataTables_wrapper .dataTables_filter {
  width: 40%;
}
@media (max-width: 767px) {
  .dataTables_wrapper .dataTables_filter {
    width: 100%;
  }
}
.dataTables_wrapper .dataTables_filter input {
  margin: 0 !important;
}
.dataTables_wrapper .dataTables_filter label,
.dataTables_wrapper .dataTables_filter input {
  width: 100%;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f1f1f1;
  vertical-align: top;
  color: #7E7E7E !important;
  margin: 0 2px;
  border: 0 !important;
  line-height: 21px;
  box-shadow: none !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #e4e4e4;
  color: #7E7E7E !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  background: #58d6e6;
  color: #fff !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-size: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:before,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:before {
  content: '';
  font-family: 'Material-Design-Iconic-Font';
  font-size: 20px;
  line-height: 35px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:before {
  content: '\f2fa';
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next:before {
  content: '\f2fb';
}
.dataTables_info,
.dataTables_paginate {
  padding: 30px;
}
@media (max-width: 767px) {
  .dataTables_info {
    display: none;
  }
}

.bootstrap-select {
  width: 100%;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
.bootstrap-select .bs-caret {
  display: none;
}
.bootstrap-select > .btn-default {
  background: none !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.bootstrap-select > .btn-default:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: calc(100% - 2px);
  width: 30px;
  background-color: #FFF;
  background-position: right calc(100% - 7px);
  background-repeat: no-repeat;
  background-image: url("/img/select.png");
  pointer-events: none;
  z-index: 5;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .bootstrap-select > .btn-default:before {
    background-image: url("/assets/select@2x.png");
    background-size: 12px 12px;
  }
}
.bootstrap-select .bs-searchbox {
  padding: 5px 5px 5px 40px;
  position: relative;
  background: #F7F7F7;
  margin-top: -10px;
}
.bootstrap-select .bs-searchbox:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  content: "\f1c3";
  font-family: 'Material-Design-Iconic-Font';
  font-size: 25px;
  padding: 4px 0 0 15px;
}
.bootstrap-select .bs-searchbox input {
  border: 0;
  background: transparent;
}
.bootstrap-select.btn-group .dropdown-menu li a.opt {
  padding-left: 17px;
}
.bootstrap-select.btn-group .dropdown-menu .no-results {
  padding: 8px 8px 0;
  background-color: #fff;
}
.bootstrap-select .check-mark {
  margin-top: -5px !important;
  font-size: 19px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  display: block !important;
  position: absolute;
  top: 11px;
  right: 15px;
}
.bootstrap-select .check-mark:before {
  content: "\f26b";
  font-family: 'Material-Design-Iconic-Font';
}
.bootstrap-select .selected .check-mark {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.bootstrap-select .notify {
  bottom: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background: #f1453d !important;
  color: #fff !important;
  text-align: center;
}
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}
.chosen-container .chosen-drop {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1px;
  border: 0;
  top: 0;
  border-radius: 2px;
}
.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
  max-height: 300px;
}
.chosen-container .chosen-results li {
  padding: 10px 17px;
  width: 100%;
}
.chosen-container .chosen-results li.highlighted {
  background: rgba(0, 0, 0, 0.075);
  color: #333;
}
.chosen-container .chosen-results li.result-selected {
  background: transparent;
  color: #5E5E5E;
  position: relative;
}
.chosen-container .chosen-results li.result-selected:before {
  content: "\f26b";
  font-family: 'Material-Design-Iconic-Font';
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 19px;
}
.chosen-container .chosen-results li.group-result {
  color: #B2B2B2;
  font-weight: normal;
  padding: 16px 15px 6px;
  margin-top: 9px;
}
.chosen-container .chosen-results li.group-result:not(:first-child) {
  border-top: 1px solid #eee;
}
.chosen-container-single .chosen-single {
  border-radius: 0;
  overflow: visible;
  height: 34px;
  padding: 6px 0 6px;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  background: none;
  box-shadow: none;
}
.chosen-container-single .chosen-single:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: calc(100% - 2px);
  width: 30px;
  background-color: #FFF;
  background-position: right calc(100% - 7px);
  background-repeat: no-repeat;
  background-image: url("/img/select.png");
  pointer-events: none;
  z-index: 5;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .chosen-container-single .chosen-single:before {
    background-image: url("/assets/select@2x.png");
    background-size: 12px 12px;
  }
}
.chosen-container-single .chosen-single div b {
  display: none;
}
.chosen-container-single .chosen-search {
  padding: 5px 5px 5px 40px;
  background: #F7F7F7;
}
.chosen-container-single .chosen-search:before {
  content: "\f1c3";
  font-family: 'Material-Design-Iconic-Font';
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  font-size: 25px;
  padding: 5px 0 0 15px;
}
.chosen-container-single .chosen-search input[type=text] {
  border: 0;
  height: 35px;
  line-height: 1.42857143;
  background-image: none !important;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border: 0;
  background: none;
}
.chosen-container-multi .chosen-choices {
  padding: 0;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  background: none;
  box-shadow: none;
}
.chosen-container-multi .chosen-choices li.search-choice {
  border-radius: 2px;
  margin: 4px 4px 0 0;
  background: #eaeaea;
  padding: 5px 23px 5px 8px;
  border: 0;
  box-shadow: none;
  font-size: 12px;
}
.chosen-container-multi .chosen-choices li.search-field input[type=text] {
  padding: 0;
  height: 31px;
}
.search-choice-close {
  cursor: pointer;
}
.search-choice-close:before {
  display: inline-block;
  font-family: 'Material-Design-Iconic-Font';
  content: "\f135";
  position: relative;
  top: 1px;
  color: #9C9C9C;
  z-index: 2;
  background: #EAEAEA;
  font-size: 12px;
}
.noUi-target {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
.noUi-background {
  background: #d4d4d4;
  box-shadow: none;
}
.noUi-horizontal {
  height: 3px;
}
.noUi-horizontal .noUi-handle {
  top: -8px;
}
.noUi-vertical {
  width: 3px;
}
.noUi-connect {
  background: #009688;
}
.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  width: 19px;
  height: 19px;
  border: 0;
  border-radius: 100%;
  box-shadow: none;
  -webkit-transition: box-shadow;
  -o-transition: box-shadow;
  transition: box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  cursor: pointer;
  position: relative;
  background-color: #009688;
}
.noUi-horizontal .noUi-handle:before,
.noUi-vertical .noUi-handle:before,
.noUi-horizontal .noUi-handle:after,
.noUi-vertical .noUi-handle:after {
  display: none;
}
.noUi-horizontal .noUi-active,
.noUi-vertical .noUi-active {
  box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.1);
}
.noUi-tooltip {
  border: 0;
  background: #d4d4d4;
  padding: 5px 10px;
}
.cp-container {
  position: relative;
}
.cp-container > .input-group input.cp-value {
  color: #000 !important;
  background: transparent !important;
}
.cp-container > .input-group .dropdown-menu {
  padding: 20px;
}
.cp-container i.cp-value {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 15px;
}
.note-editor .note-toolbar,
.note-popover .note-toolbar,
.note-editor .popover-content,
.note-popover .popover-content {
  background: #fff;
  border-color: #e4e4e4;
  margin: 0;
  padding: 10px 0 15px;
  text-align: center;
}
.note-editor .note-toolbar > .btn-group,
.note-popover .note-toolbar > .btn-group,
.note-editor .popover-content > .btn-group,
.note-popover .popover-content > .btn-group {
  display: inline-block;
  float: none;
  box-shadow: none;
}
.note-editor .note-toolbar > .btn-group .btn,
.note-popover .note-toolbar > .btn-group .btn,
.note-editor .popover-content > .btn-group .btn,
.note-popover .popover-content > .btn-group .btn {
  margin: 0 1px;
}
.note-editor .note-toolbar > .btn-group > .active,
.note-popover .note-toolbar > .btn-group > .active,
.note-editor .popover-content > .btn-group > .active,
.note-popover .popover-content > .btn-group > .active {
  background: #00BCD4;
  color: #fff;
}
.note-editor .note-toolbar .btn,
.note-popover .note-toolbar .btn,
.note-editor .popover-content .btn,
.note-popover .popover-content .btn {
  height: 40px;
  border-radius: 2px !important;
  box-shadow: none !important;
}
.note-editor .note-toolbar .btn:active,
.note-popover .note-toolbar .btn:active,
.note-editor .popover-content .btn:active,
.note-popover .popover-content .btn:active {
  box-shadow: none;
}
.note-editor .note-toolbar .note-palette-title,
.note-popover .note-toolbar .note-palette-title,
.note-editor .popover-content .note-palette-title,
.note-popover .popover-content .note-palette-title {
  margin: 0 !important;
  padding: 10px 0 !important;
  font-size: 13px !important;
  text-align: center !important;
  border: 0 !important;
}
.note-editor .note-toolbar .note-color-reset,
.note-popover .note-toolbar .note-color-reset,
.note-editor .popover-content .note-color-reset,
.note-popover .popover-content .note-color-reset {
  padding: 0 0 10px !important;
  margin: 0 !important;
  background: none;
  text-align: center;
}
.note-editor .note-toolbar .note-color .dropdown-menu,
.note-popover .note-toolbar .note-color .dropdown-menu,
.note-editor .popover-content .note-color .dropdown-menu,
.note-popover .popover-content .note-color .dropdown-menu {
  min-width: 335px;
}
.note-editor .note-statusbar .note-resizebar,
.note-popover .note-statusbar .note-resizebar {
  border-color: #E8E8E8;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar,
.note-popover .note-statusbar .note-resizebar .note-icon-bar {
  border-color: #BCBCBC;
}
.note-editor .fa,
.note-popover .fa {
  font-style: normal;
  font-size: 20px;
  vertical-align: middle;
}
.note-editor .fa:before,
.note-popover .fa:before {
  font-family: 'Material-Design-Iconic-Font';
}
.note-editor .fa.fa-magic:before,
.note-popover .fa.fa-magic:before {
  content: "\f16a";
}
.note-editor .fa.fa-bold:before,
.note-popover .fa.fa-bold:before {
  content: "\f23d";
}
.note-editor .fa.fa-italic:before,
.note-popover .fa.fa-italic:before {
  content: "\f245";
}
.note-editor .fa.fa-underline:before,
.note-popover .fa.fa-underline:before {
  content: "\f24f";
}
.note-editor .fa.fa-font:before,
.note-popover .fa.fa-font:before {
  content: "\f242";
}
.note-editor .fa.fa-list-ul:before,
.note-popover .fa.fa-list-ul:before {
  content: "\f247";
}
.note-editor .fa.fa-list-ol:before,
.note-popover .fa.fa-list-ol:before {
  content: "\f248";
}
.note-editor .fa.fa-align-left:before,
.note-popover .fa.fa-align-left:before {
  content: "\f23b";
}
.note-editor .fa.fa-align-right:before,
.note-popover .fa.fa-align-right:before {
  content: "\f23c";
}
.note-editor .fa.fa-align-center:before,
.note-popover .fa.fa-align-center:before {
  content: "\f239";
}
.note-editor .fa.fa-align-justify:before,
.note-popover .fa.fa-align-justify:before {
  content: "\f23a";
}
.note-editor .fa.fa-indent:before,
.note-popover .fa.fa-indent:before {
  content: "\f244";
}
.note-editor .fa.fa-outdent:before,
.note-popover .fa.fa-outdent:before {
  content: "\f243";
}
.note-editor .fa.fa-text-height:before,
.note-popover .fa.fa-text-height:before {
  content: "\f246";
}
.note-editor .fa.fa-table:before,
.note-popover .fa.fa-table:before {
  content: "\f320";
}
.note-editor .fa.fa-link:before,
.note-popover .fa.fa-link:before {
  content: "\f18e";
}
.note-editor .fa.fa-picture-o:before,
.note-popover .fa.fa-picture-o:before {
  content: "\f17f";
}
.note-editor .fa.fa-minus:before,
.note-popover .fa.fa-minus:before {
  content: "\f22f";
}
.note-editor .fa.fa-arrows-alt:before,
.note-popover .fa.fa-arrows-alt:before {
  content: "\f16d";
}
.note-editor .fa.fa-code:before,
.note-popover .fa.fa-code:before {
  content: "\f13a";
}
.note-editor .fa.fa-question:before,
.note-popover .fa.fa-question:before {
  content: "\f1f5";
}
.note-editor .fa.fa-eraser:before,
.note-popover .fa.fa-eraser:before {
  content: "\f23f";
}
.note-editor .fa.fa-square:before,
.note-popover .fa.fa-square:before {
  content: "\f279";
}
.note-editor .fa.fa-circle-o:before,
.note-popover .fa.fa-circle-o:before {
  content: "\f26c";
}
.note-editor .fa.fa-times:before,
.note-popover .fa.fa-times:before {
  content: "\f136";
}
.note-editor .note-air-popover .arrow,
.note-popover .note-air-popover .arrow {
  left: 20px;
}
.note-editor {
  overflow: visible;
  border: 1px solid #e4e4e4;
}
.note-editor .note-editable {
  padding: 20px 23px;
}
.bootstrap-datetimepicker-widget {
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
  bottom: auto !important;
  top: 0 !important;
}
.bootstrap-datetimepicker-widget:after,
.bootstrap-datetimepicker-widget:before {
  display: none !important;
}
.bootstrap-datetimepicker-widget table td {
  text-shadow: none;
}
.bootstrap-datetimepicker-widget table td span {
  margin: 0;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: transparent;
}
.bootstrap-datetimepicker-widget .glyphicon {
  font-family: 'Material-Design-Iconic-Font';
  font-size: 18px;
}
.bootstrap-datetimepicker-widget .glyphicon-chevron-left:before {
  content: "\f2ff";
}
.bootstrap-datetimepicker-widget .glyphicon-chevron-right:before {
  content: "\f301";
}
.bootstrap-datetimepicker-widget .glyphicon-time:before {
  content: "\f337";
}
.bootstrap-datetimepicker-widget .glyphicon-calendar:before {
  content: "\f32e";
}
.bootstrap-datetimepicker-widget .glyphicon-chevron-up:before {
  content: "\f1e5";
}
.bootstrap-datetimepicker-widget .glyphicon-chevron-down:before {
  content: "\f1e4";
}
.bootstrap-datetimepicker-widget a[data-action] {
  color: #009688;
}
.timepicker-picker .btn {
  box-shadow: none !important;
}
.timepicker-picker table tbody tr + tr:not(:last-child) {
  background: #009688;
  color: #fff;
}
.timepicker-picker table tbody tr + tr:not(:last-child) td {
  border-radius: 0;
}
.timepicker-picker .btn,
.timepicker-picker .btn:hover {
  background: #fff;
  color: #333;
}
.datepicker table thead tr th {
  border-radius: 0;
  color: #757779;
}
.datepicker table thead tr th .glyphicon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 29px;
}
.datepicker table thead tr th:hover .glyphicon {
  background: rgba(0, 0, 0, 0.2);
}
.datepicker table thead tr:first-child th {
  background: transparent;
  color: #000;
  padding: 20px 0;
}
.datepicker table thead tr:first-child th:hover {
  background: transparent;
}
.datepicker table thead tr:first-child th.picker-switch {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}
.datepicker table thead tr:last-child th {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
}
.datepicker table thead tr:last-child th:first-child {
  padding-left: 20px;
}
.datepicker table thead tr:last-child th:last-child {
  padding-right: 20px;
}
.datepicker table tbody tr:last-child td {
  padding-bottom: 25px;
}
.datepicker table tbody tr td:first-child {
  padding-left: 13px;
}
.datepicker table tbody tr td:last-child {
  padding-right: 13px;
}
.datepicker table td.day {
  width: 35px;
  height: 35px;
  line-height: 20px;
  color: #333;
  position: relative;
  padding: 0;
  background: transparent;
}
.datepicker table td.day:hover {
  background: none;
}
.datepicker table td.day:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: -33px;
  display: inline-block;
  background: transparent;
  position: static;
  text-shadow: none;
  border-bottom-color: transparent !important;
}
.datepicker table td.day.old,
.datepicker table td.day.new {
  color: #CDCDCD;
}
.datepicker table td:not(.today):not(.active):hover:before {
  background: #F0F0F0;
}
.datepicker table td.today {
  color: #333;
}
.datepicker table td.today:before {
  background-color: #E2E2E2;
}
.datepicker table td.active {
  color: #000;
  text-shadow: none;
}
.datepicker table td.active:before {
  background-color: #e0f7fa;
}
.datepicker table td.active:hover {
  text-shadow: none;
  color: #000;
}
.datepicker-months .month,
.datepicker-years .year,
.timepicker-minutes .minute,
.timepicker-hours .hour {
  border-radius: 50%;
}
.datepicker-months .month:not(.active):hover,
.datepicker-years .year:not(.active):hover,
.timepicker-minutes .minute:not(.active):hover,
.timepicker-hours .hour:not(.active):hover {
  background: #000;
}
.datepicker-months .month.active,
.datepicker-years .year.active,
.timepicker-minutes .minute.active,
.timepicker-hours .hour.active {
  background: #009688;
}
.timepicker-minutes .minute,
.timepicker-hours .hour {
  padding: 0;
}
.fileinput {
  position: relative;
  padding-right: 35px;
}
.fileinput .close {
  position: absolute;
  top: 5px;
  font-size: 12px;
  float: none;
  opacity: 1;
  font-weight: 500;
  border: 1px solid #ccc;
  width: 19px;
  text-align: center;
  height: 19px;
  line-height: 16px;
  border-radius: 50%;
  right: 0;
  visibility: hidden;
}
.fileinput .close:hover {
  background: #eee;
}
.fileinput .input-group-addon {
  padding: 0 10px;
  vertical-align: middle;
}
.fileinput .fileinput-preview {
  width: 200px;
  height: 150px;
  position: relative;
}
.fileinput .fileinput-preview img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -13px;
}
.fileinput .fileinput-preview:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
}
.fileinput-exists .close {
  visibility: visible;
}
.lg-outer .lg-thumb-outer {
  background-color: #1D1D1D;
}
.lg-outer .lg-thumb-item {
  border-radius: 50%;
  width: 60px !important;
  display: inline-block;
  height: 60px;
  border: 0;
  float: none;
  margin: 0 5px;
}
.lg-outer .lg-thumb-item:hover {
  box-shadow: 0 0 0px 4px rgba(255, 255, 255, 0.1);
}
.lg-outer .lg-image {
  border-radius: 3px;
}
.lg-outer .lg-toogle-thumb {
  border-radius: 50%;
  color: #333;
  height: 51px;
  width: 51px;
  line-height: 41px;
  background-color: #fff;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
}
.lg-outer .lg-toogle-thumb:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  color: #000;
}
.lg-outer:not(.lg-thumb-open) .lg-toogle-thumb {
  top: -70px;
}
.lg-outer.lg-thumb-open .lg-toogle-thumb {
  top: -26px;
}
.lg-thumb.group {
  padding: 20px 0;
}
.lg-slide em {
  font-style: normal;
}
.lg-slide em h3 {
  color: #fff;
  margin-bottom: 5px;
}
.lg-slide .video-cont {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}
.lg-outer .lg-item {
  background-image: none !important;
}
.lg-outer .lg-item:before {
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-right-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  -webkit-animation-name: loader;
  animation-name: loader;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading > i {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
  border-left-color: #fff;
  border-style: solid;
}
.swal2-modal {
  border-radius: 2px;
  padding: 30px !important;
  font-family: roboto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}
.swal2-modal h2 {
  font-size: 16px;
  position: relative;
  z-index: 1;
  color: #333;
  line-height: inherit;
  margin: 0 0 5px;
  font-weight: 500;
}
.swal2-modal .swal2-icon {
  margin-top: 0;
  margin-bottom: 20px;
}
.swal2-modal .swal2-content {
  color: #777777;
  font-size: 13px;
  font-weight: normal;
}
.swal2-modal .styled {
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 2px;
  margin: 0 2px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: inherit;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.swal2-modal .styled:not(:hover) {
  opacity: 0.85;
  filter: alpha(opacity=85);
}
.swal2-modal hr {
  margin: 10px 0;
}
.swal2-container.in {
  background-color: rgba(0, 0, 0, 0.2);
}
.twitter-typeahead {
  width: 100%;
}
.twitter-typeahead .tt-menu {
  min-width: 200px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: none;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.twitter-typeahead .tt-menu.tt-open:not(.tt-empty) {
  display: block;
}
.twitter-typeahead .tt-suggestion {
  padding: 8px 17px;
  color: #333;
  cursor: pointer;
}
.twitter-typeahead .tt-suggestion:hover,
.twitter-typeahead .tt-cursor {
  background-color: rgba(0, 0, 0, 0.075);
}
.twitter-typeahead .tt-hint {
  color: #818181 !important;
}
.mCSB_scrollTools {
  width: 5px;
}
.mCSB_scrollTools .mCSB_dragger_bar {
  border-radius: 0 !important;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCSB_scrollTools.mCSB_scrollTools_vertical {
  margin: 0 !important;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  height: 10px;
}
html:not(.ie9) .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.4);
}
html:not(.ie9) .mCS-minimal-dark.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.4);
}
html.ie9 .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: #000000;
}
html.ie9 .mCS-minimal-dark.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
  background: #000000;
}
.mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.dropzone {
  border: 0;
  background-color: #f3f3f3;
  border-radius: 2px;
}
.dropzone .dz-preview .dz-image {
  border-radius: 2px !important;
  border: 4px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.dropzone .dz-preview .dz-remove {
  position: absolute;
  top: -3px;
  right: -4px;
  z-index: 20;
  font-size: 0;
  width: 20px;
  height: 20px;
  background-color: #f1453d;
  border-radius: 50%;
}
.dropzone .dz-preview .dz-remove:hover {
  background-color: #ef2e25;
  text-decoration: none;
}
.dropzone .dz-preview .dz-remove:before {
  content: '\f136';
  font-size: 11px;
  font-family: 'Material-Design-Iconic-Font';
  color: #fff;
  font-weight: bold;
  line-height: 20px;
}
.dropzone .dz-message span {
  font-size: 20px;
  color: #b5b5b5;
  text-shadow: 0 1px 1px #fff;
  border: 2px solid #e4e4e4;
  padding: 15px 20px;
  display: inline-block;
  border-radius: 5px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.dropzone .dz-message span:before {
  content: '\f21e';
  font-family: 'Material-Design-Iconic-Font';
  margin: -6px 10px 0 0;
  font-size: 25px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.dropzone:hover .dz-message span {
  border-color: #d7d7d7;
  color: #a8a8a8;
}
.tempory-images img {
  width: 100%;
  height: auto;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.no-padding {
  padding: 0 !important;
}
.last {
  margin-bottom: 0;
}
.logo {
  width: 200px;
  margin: 0 auto 50px;
  text-align: center;
}
.logo img {
  width: 100%;
}
.form-group small {
  display: block;
  margin-bottom: 8px;
  color: #AEAEAE;
}
.form-header {
  margin: 40px 0 20px;
  line-height: 100%;
  font-size: 16px;
  font-weight: 400;
}
.form-header.first {
  margin-top: 0;
}
.btn {
  padding: 8px 20px;
}
.login-content {
  background: none;
}
.login-content button {
  margin-top: 10px;
  width: 100%;
}
.login-actions {
  margin-top: 30px;
}
.login-actions .checkbox {
  float: left;
  margin: 0;
  position: relative;
}
.login-actions a {
  float: right;
  color: #607d8b;
}
.fz-block {
  background: none;
}
.fz-block small {
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 20px;
}
.fz-block p {
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 50px;
}
.slider-wrapper {
  max-width: 300px;
}
.hi-logo span {
  color: #757779;
}
.h-inner {
  padding: 17px 10px 17px 35px;
  text-align: center;
}

.ha-menu {
  padding: 0px 35px;
}
small {
  color: #6d7074 !important;
}
.wrapper-one {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}
.action-nav {
  float: right;
}
.action-nav button {
  margin-left: 10px;
}
.user-profile a img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user-profile a:hover {
  background-color: none;
}
.back-arrow {
  font-size: 20px;
  border-right: 1px solid #dcddde;
}
.back-arrow a {
  display: block;
  height: 70px;
  padding: 20px 10px;
  color: #6c6f74;
}
th {
  border-color: #e2e2e2 !important;
}
td {
  border-color: #e2e2e2 !important;
  vertical-align: middle !important;
}
td figure {
  max-width: 15%;
  display: inline-block;
  vertical-align: middle;
  padding-right: 20px;
  margin-right: -5px;
}
td img {
  width: 100%;
  height: auto;
}
td .text-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 86%;
  margin-right: -5px;
}
table {
  color: #333333;
  page-break-inside:auto
}
tr    { page-break-inside:avoid; page-break-after:auto }
table a {
  color: #333333;
}
table .drl {
  color: #0faa42;
  font-style: normal;
}
table .queue,
table .message {
  text-align: center;
}
table .more {
  text-align: right;
}
table .more ul {
  margin-bottom: 0;
}
table .campaign {
  color: #000;
  font-weight: 700;
  width: 250px;
}
table .rank {
  width: 100px;
}
table .rank .star {
  margin-right: 5px;
}
table .now,
table .first,
table .change {
  width: 20px;
}
table .change {
  color: #0faa42;
}
table .result .dot {
  margin-right: 5px;
}
table .result small {
  display: block;
  text-transform: uppercase;
}
table div {
  display: table;
  vertical-align: middle;
  &.asset-actions {
    float: left;
  }
  &.asset-text {
    margin-top: 4px;
  }
}

table i.alerts {
  color: #fff;
  font-style: normal;
  background: #f1453d;
  padding: 1px 5px;
  border-radius: 2px;
  right: 7px;
  top: -3px;
  font-size: 10px;
  line-height: 15px;
  a {
    color: inherit;
  }
}
table i.zmdi-more-vert {
  font-size: 24px;
  line-height: normal;
}

/*table.asset-table {
  color: white;
  a {
    color: white;
  }
}*/

.dataTable th {
  border-bottom: 0 !important;
}
/* Cards
----------------------------------- */
.card.form {
  margin-bottom: 30px;
}
.card.form th {
  color: #000;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.card.form td {
  font-weight: 400;
  color: #333333;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.card.form .user-type {
  color: #6d7074;
}
.card.form table {
  border-bottom: 1px solid #e2e2e2 !important;
  margin-bottom: 30px;
}
.card.search {
  margin-bottom: 0;
}
.card.asset .card-header {
  background-color: #f7f7f7;
  min-height: 145px;
}
.card.asset .card-header small {
  display: block;
  margin-bottom: 10px;
}
.card.asset .card-header h2 {
  line-height: normal;
}
.card.asset.flagged .header-wrap {
  padding-left: 15px;
  position: relative;
}
.card.asset.flagged .header-wrap .dot {
  position: absolute;
  top: -18px;
  left: 34px;
}
.card.asset .card-header.collapse {
  min-height: 0;
  height: 0;
}
.card.keyword .card-header {
  background-color: #f7f7f7;
}
.card.keyword .card-header small {
  display: block;
  margin-bottom: 10px;
}
.card.keyword .card-header h2 {
  line-height: normal;
}
/* Forms
----------------------------------- */
.form-control {
  border-color: #e2e2e2;
}
.keywords-tag {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}
.keywords-tag li {
  background-color: #607D8B;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  margin-right: 2px;
  margin-top: 5px;
  border-radius: 2px;
  font-weight: 500;
  line-height: 0;
}
.keywords-tag i {
  font-weight: 600;
  margin-left: 10px;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.form-footer .update-buttons {
  float: left;
}
.form-footer .update-buttons a {
  color: #607D8B;
}
.form-footer .archive {
  float: right;
}
.form-footer .archive a {
  color: #9fa1a3;
  padding-right: 0;
}
.form-footer a {
  font-weight: 500;
}
.bootstrap-select .btn {
  text-transform: none !important;
}
/* Clients & Campaigns
----------------------------------- */
.search-wrapper {
  margin-bottom: 30px;
}
.search .card-padding {
  padding: 10px 30px !important;
}
.search input {
  border: none;
}
.filter-options {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}
.filter-options li {
  background-color: #607D8B;
  color: #fff;
  display: inline-block;
  padding: 8px 15px;
  margin-right: 2px;
  margin-top: 5px;
  border-radius: 2px;
  font-weight: 500;
  line-height: 0;
  font-size: 15px;
}
.filter-options i {
  font-weight: 600;
  margin-left: 10px;
  font-size: 17px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
/* Campaign
----------------------------------- */
.campaign .h-inner {
  padding: 17px 10px 17px 60px;
}
.graphs .card-header {
  padding-left: 15px;
  padding-right: 15px;
}
.graphs .card-body {
  padding: 0 15px 30px;
}
.stat {
  display: block;
  margin-bottom: 20px;
  font-size: 50px;
  font-weight: 300;
}
.stat .swing {
  font-size: 13px;
  color: #0faa42;
  font-weight: 400;
}
.campaign-info {
  border-top: 1px solid #e2e2e2;
  padding: 30px 15px 20px;
  a {
    display: block;
    button {
      line-height: 1;
      padding: 6px;
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
.campaign-info h5 {
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.view-more {
  border-top: 1px solid #e2e2e2;
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.source {
  text-transform: uppercase;
  color: #9fa1a3;
  display: block;
  margin-top: 5px;
}
.recent-notes textarea {
  width: 100%;
  max-width: 100%;
  height: 100px;
  max-height: 100px;
  padding: 10px;
  border: 1px solid #e2e2e2;
}
.note {
  margin-bottom: 26px;
}
.note h2 {
  margin: 0;
  line-height: 100%;
  font-size: 16px;
  font-weight: 500;
}
.note small {
  display: block;
  margin-top: 8px;
  color: #AEAEAE;
  line-height: 160%;
}
.note .note-text {
  margin-left: 57px;
  margin-top: 26px;
}
.campaigns-keywords .tab-nav {
  margin-bottom: 30px;
}
.graph-wrapper {
  padding: 26px 30px;
}
.graph-wrapper .bar {
  margin-top: 20px;
}
.graph-wrapper .bar:first-child {
  margin-top: 0;
}
.graph-wrapper small {
  margin-bottom: 10px;
  display: block;
}
.bar {
  display: block;
}
.fa-bar-wrapper {
  background-color: #dadbda;
  display: flex !important;
  div {
    transition: width 1s;
    -webkit-transition: width 1s;
    &.squeezed {
      width: 0% !important;
    }
  }
}
 .bar.tall .fa-bar-wrapper {
    height: 44px;
  }
.pdf .bar.tall .fa-bar-wrapper {
    height: 30px;
  }
.pdf .bar.tall .fa-bar-wrapper div {
  padding-top: 7px;
  .squeezed {
      width: auto;
    }
}
.pdf .bar.medium .fa-bar-wrapper {
  margin-bottom: 5px;
  margin-top: 5px;
}
.bar.tall .fa-bar-wrapper div {
    color: white;
    padding-top: 13px;
    overflow: hidden;
}
.bar.tall .fa-bar-wrapper div.neutral {
    color: black;
}
.bar.medium .fa-bar-wrapper {
  height: 25px;
}
.bar.medium .fa-bar-wrapper div {
  padding-top: 3px;
  color: white;
  overflow: hidden;
}
.bar.medium .fa-bar-wrapper div.neutral {
    color: black;
  }
  .bar .fa-bar-wrapper {
    height: 3px;
    display: block;
  }
  div.fa-bar-wrapper div {
    color: transparent;
    height: 100%;
    float: left;
    display: block;
    text-align: center;
    border-right: 1px solid white;
  }
  div.fa-bar-wrapper div.unfavorable {
    background:#f34141;
    margin-left: 0; //-3px;
  }
  div.fa-bar-wrapper div.favorable {
    background:#52b760;
  }
  div.fa-bar-wrapper div.neutral {
    background:#dadada;
  }
.wall .w-item .card-body {
  margin-left: 58px;
}
.star {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.star i {
  font-size: 20px !important;
  color: #e8e8e8;
}
.star i.gold {
  color: #ffbf1f;
}
.dot {
  display: inline-block;
  vertical-align: middle;
}
.dot i.unmoderated {
  font-size: 13px !important;
  color: #fff !important;
  border: 1px solid #adadad;
  border-radius: 13px;
  height: 13px;
}
.dot i {
  font-size: 13px !important;
  color: #e8e8e8 !important;
}
.dot i.green {
  color: #4caf50 !important;
}
.dot i.red {
  color: #f44336 !important;
}
.alert {
  // background-color: #607d8b;
}
.alert button {
  margin-top: 3px;
}
.alert i {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin-right: 10px;
  line-height: normal;
}
.table-pagination {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-outer {
  position: relative;
  padding-bottom: 55px;
}
.card-outer .card,
.card-outer .dataTables_wrapper {
  position: static!important;
}
tfoot {
  display: none;
}
.dataTables_paginate {
  padding: 20px 0px !important;
}
.dataTables_paginate .paginate_button {
  background: #d9d9d9 !important;
}
.dataTables_paginate .paginate_button.disabled {
  background: transparent !important;
}
.dataTables_paginate .paginate_button.current {
  background-color: #9d9d9d !important;
}
.dataTables_length {
  padding: 20px 0px !important;
}
.dataTables_length label {
  margin: 0 !important;
}
.thumbnail {
  padding: 0;
  border: none;
}
.fileinput {
  display: block;
  margin-bottom: 0;
}
.fileinput.fileinput-new .thumbnail {
  display: none;
}
.fileinput .fileinput-preview {
  width: auto;
  max-width: 200px;
  height: auto;
}
.fileinput .fileinput-preview img {
  margin-top: 0;
}
.calendar {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #e2e2e2;
  background: #fff;
}
.calendar input {
  border: none;
  padding: 7px 10px !important;
  background-image: url(/img/caret.png);
  background-position: 96% center;
  background-size: 23px 20px;
  background-repeat: no-repeat;
}
