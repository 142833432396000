body {
    background-color: #ebebeb;
    font-family: 'Proxima Nova', sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-print-color-adjust: exact !important;
}
.page-break {
    page-break-before: always !important;
}
#flot-placeholder {
    height: 100%;
    width: 100%;
    min-width: 450px;
}
.campaigns-keywords {
    td.result {
        display: flex;
        align-items: center;
    }
}
.campaigns-reports {
     .displayed-link {
        display: block;
        margin-left: 35px;
     }
}
