.content {
    padding: 50px 0;
}

td.sorting_asc .zmdi-unfold-more::before {
    content: '\f2fc';
}

td.sorting_desc .zmdi-unfold-more::before {
    content: '\f2f9';
}
