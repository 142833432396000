body[class*='campaigns-reports-'] {

    background-color: #fff !important;
    margin: 0;
    padding: 0;

    a {
        color: #827552;
        text-decoration: none;
    }

    .navbar, .ha-menu { display: none; }

    .block-header {
        padding-top: 25mm;
        h2 {
            font-size: 22px;
            font-weight: bold;
        }
    }

    #flot-placeholder {
        min-width: 350px;
    }
    .table-data {
        .container,
        .row,
        .card,
        .card-body
        .table {
            background-color: #fff !important;
            width: 100%;
            margin: 0 !important;
            padding: 0 !important;
        }
        .col-md-12 {
            padding: 0 !important;
        }
        .card-header {}
        .card-body {}
    }
    .table {
        margin: 0 !important;
        thead tr, tbody tr {}
        tbody tr:last-child {
            border-bottom: 1px solid #dee2e6;
        }
    }
    .table-suggested .card {
        margin-bottom: 50px !important;
    }
    .page-break {
        page-break-before: always !important;
        break-before: always !important;
    }
    .print-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        img {
            height: auto;
            width: 100%;
            max-width: 100%;
            &.hr-logo {
                max-width: 25%;
            }
            &.hn-logo {
                max-width: 10%;
            }
        }
    }
    .no-border {
        border: none !important;
    }
    .footer-repeat {
        display: table-footer-group;
    }
}
.pdf-footer {
    font-size: 26px;
}
@page {
    size: A4;
    margin: 25mm 25mm 25mm 25mm;
}
@media print {
    .campaigns-reports {
        .content {
            padding: 0;
            table { page-break-inside:auto }
            tr    { page-break-inside:avoid; page-break-after:auto }
            thead { display:table-header-group }
            tfoot { display:table-footer-group }
        }
    }
}
